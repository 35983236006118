import { AlertCard, colors, SelectInput, type } from '@candidco/enamel';
import styled from 'styled-components/macro';
import { HRDividerLine } from 'styles/layout.css';
import ThumbsUpIcon from 'assets/thumbs-up.svg?react';
import { Input, LinkButton } from 'styles/inputs.css';

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;

  font-weight: ${type.weight.bold};
  font-size: ${type.size.large};
  line-height: 20px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

export const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;

  a {
    font-size: ${type.size.small};
  }
`;

export const StyledDivider = styled(HRDividerLine)`
  margin: 0rem;
`;

export const StyledModal = styled.div`
  @media ${({ theme }) => theme.mediaQueries.isTabletAndAbove} {
    width: 42.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const TextArea = styled.textarea`
  height: 5rem;
  width: 100%;

  border: 1px solid ${colors.black40};
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 2rem;

  &::placeholder {
    color: ${colors.text60}
    font-weight: ${type.weight.default};
    line-height: ${type.lineHeight.default};
  } 
`;

export const LabelAndInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const StyledSelectInput = styled(SelectInput)`
  padding-top: 0.125rem;
  margin-bottom: 2rem;
`;

//Alert card is currently broken when header is hidden, but icon is not.
//To get around this, style the header like it's the body
export const StyledAlertCard = styled(AlertCard)`
  h5 {
    font-weight: ${type.weight.default};
    line-height: ${type.lineHeight.default};
    font-size: ${type.size.default};
    margin: 0px;
  }
  align-self: stretch;
  margin-bottom: 2rem;

  &:first-child {
    margin-bottom: 0;
  }
`;

export const AlertWrapper = styled.div`
  display: inline;
`;

export const StyledLinkButton = styled(LinkButton)`
  font-weight: ${type.weight.bold};
`;

export const CheckboxInput = styled(Input)`
  appearance: checkbox;
  width: 1.5rem;
  margin-right: 1rem;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

export const AnswerRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5rem;

  > button:not(:first-child) {
   margin-left: 1rem;
  `;

export const Question = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const FreeRefinementWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReplacementCopy = styled.div`
  color: ${({ theme }) => theme.colors.text70};
`;

export const MonitoringLink = styled.a`
  padding-bottom: 1rem;
`;

export const StyledThumbsUpIcon = styled(ThumbsUpIcon)`
  height: 1.25rem;
  margin-right: 0.5rem;
  path {
    fill: ${({ theme }) => theme.colors.blue50};
  }
`;
