import React, { FormEvent, useContext, useState } from 'react';

import {
  fetchContractRates,
  fetchUserEmails,
  getContractRates,
  getUserEmails,
} from 'pages/PracticeManagement/shared/slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, NotificationContext } from '@candidco/enamel';
import { useParams } from 'react-router-dom';
import { PracticeManagerParams } from 'pages/PracticeManagement/PracticeManager';
import { useMediaQuery } from '@material-ui/core';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';
import {
  AddContractRatesDocument,
  AddContractRatesMutation,
  AddContractRatesMutationVariables,
  ContractRateType,
} from 'generated/core/graphql';
import { useIsLoading } from 'state/system';
import {
  dollarFormat,
  filtered,
  fixedWidthProps,
  flexibleWidthProps,
  handleRateChange,
  renderDateTime,
} from 'pages/PracticeManagement/PricingTable/utils';
import {
  Flex,
  StyledButton,
  StyledDivider,
  RateInput,
  RateWrapper,
  SearchIcon,
  SearchInput,
  SearchWrapper,
  StyledTable,
} from 'pages/PracticeManagement/PricingTable/PricingTable.css';
import { useGQLMutation } from 'hooks/useGQL';

export type ModifiableRateInCents = {
  id: string;
  originalValue: number;
  value: number;
  touched: boolean;
};
// creating a type that swaps the numerical rate for an object with
// the id, value, and a boolean to track if the value has been modified
export type ModifiableContractRateType = Omit<
  ContractRateType,
  'rateInCents'
> & {
  rateInCents: ModifiableRateInCents;
};

export const PricingTable = () => {
  const dispatch = useDispatch();
  const { showNotification } = useContext(NotificationContext);
  const { checkHasAccess, userInfo } = useContext(AuthContext);
  const { practiceId } = useParams<PracticeManagerParams>();
  const contractRates = useSelector(getContractRates);
  const initialModifiableContractRates =
    contractRates?.map((rate) => ({
      // Create a new object with the same properties as the original rate,
      // but with a new rateInCents object instead of a number.
      // this gives the rate column the properties needed to track changes.
      ...rate,
      rateInCents: {
        id: rate.id,
        originalValue: rate.rateInCents,
        value: rate.rateInCents,
        touched: false,
      },
    })) ?? [];
  const [contractRatesHaveDisplayed, setContractRatesHaveDisplayed] =
    // State to track if contract rates have been displayed at least once;
    // This is used to prevent the contract rates from disappearing behind
    // a loading spinner while saving changes
    useState(false);
  const [modifiableContractRates, setModifiableContractRates] = useState<
    ModifiableContractRateType[]
  >(initialModifiableContractRates);
  const [search, setSearch] = useState('');
  const userEmails = useSelector(getUserEmails);
  const [addContractRates] = useGQLMutation<
    AddContractRatesMutation,
    AddContractRatesMutationVariables
  >(AddContractRatesDocument);

  const getCreatedByEmails = (rates: ContractRateType[]) => {
    const userIds = rates.map((rate) => rate.createdBy);
    userIds.forEach(
      (id) => id && dispatch(fetchUserEmails({ id: id.toString() }))
    );
  };

  const handleRateInputChange = (
    e: FormEvent<HTMLInputElement>,
    rateInCents: ModifiableRateInCents,
    modifiableContractRates: ModifiableContractRateType[]
  ) => {
    const inputValue = e.currentTarget.value;
    const validInput =
      /^\d*\.{1}\d{1,3}$/.test(inputValue) || inputValue === '';

    // the following is true if the user is increasing the decimal places;
    // you can only have 2 decimal places, so we want to multiply the input by 10
    const increaseInDecimalPlaces = /^\d*\.?\d{3}$/.test(inputValue) ? 10 : 1;

    // the following is true if the user is decreasing the decimal places;
    // you can only have 2 decimal places, so we want to divide the input by 10
    const decreaseInDecimalPlaces = /^\d*\.?\d{1}$/.test(inputValue) ? 0.1 : 1;

    // if the input is valid, we want to multiply the input by 10 if the user is increasing the decimal places
    // and divide by 10 if the user is decreasing the decimal
    const decimalPlaceModifier =
      increaseInDecimalPlaces * decreaseInDecimalPlaces;

    if (validInput) {
      setModifiableContractRates(
        handleRateChange(
          // since the underlying value is in cents, we also need to multiply by 100
          Number(inputValue) * 100 * decimalPlaceModifier || 0,
          rateInCents,
          modifiableContractRates
        )
      );
    }
  };

  const handleSave = async () => {
    if (!modifiableContractRates) {
      return;
    }
    const modifiedRates = modifiableContractRates.filter(
      (rate) => rate.rateInCents.touched
    );
    const contractRatesInput = modifiedRates.map((rate) => ({
      accountId: rate.accountId,
      sku: rate.sku,
      createdBy: Number(userInfo?.id),
      rateInCents: Number(Math.round(rate.rateInCents.value)),
    }));
    try {
      await addContractRates({ contractRates: contractRatesInput });
      showNotification('Contract rates saved successfully', 'success');
    } catch (error) {
      showNotification('Error saving contract rates', 'error');
    }
    dispatch(fetchContractRates({ practiceId }));
  };

  useEffect(() => {
    dispatch(fetchContractRates({ practiceId }));
    if (contractRates) {
      getCreatedByEmails(contractRates);
      setModifiableContractRates(
        contractRates.map((rate) => ({
          ...rate,
          rateInCents: {
            id: rate.id,
            originalValue: rate.rateInCents,
            value: rate.rateInCents,
            touched: false,
          },
        }))
      );
    }
  }, [dispatch, contractRates, practiceId]);

  const areContractRatesLoading = useIsLoading(fetchContractRates.type);
  useEffect(() => {
    if (!areContractRatesLoading && contractRates) {
      setContractRatesHaveDisplayed(true);
    }
  }, [areContractRatesLoading, contractRates]);

  const isDesktop = useMediaQuery('(min-width: 960px)');
  const canEdit = checkHasAccess(ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN);
  const canSave =
    !areContractRatesLoading &&
    modifiableContractRates?.some((rate) => rate.rateInCents.touched);

  return (
    <>
      <Flex>
        <SearchWrapper>
          <SearchInput
            name="search"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
            placeholder="Search"
          />
          <SearchIcon />
        </SearchWrapper>
        <StyledButton
          buttonType="secondary"
          isLoading={areContractRatesLoading}
          onClick={handleSave}
          disabled={!(canEdit && canSave)}
        >
          Save changes
        </StyledButton>
      </Flex>
      <StyledDivider />
      {areContractRatesLoading && !contractRatesHaveDisplayed ? (
        // Don't show loading spinner if contract rates have already been displayed
        <Loading />
      ) : (
        <StyledTable
          data={
            canEdit
              ? modifiableContractRates &&
                filtered(search, modifiableContractRates)
              : contractRates && filtered(search, contractRates)
          }
          columns={[
            {
              name: 'sku',
              label: 'SKU',
              options: {
                setCellProps: () => flexibleWidthProps(isDesktop),
                setHeaderProps: () => flexibleWidthProps(isDesktop),
              },
            },
            {
              // We update this field from the FE when the rate is modified;
              // it is not the original creator of the contract rate for this SKU
              name: 'createdBy',
              label: 'Last Modified By',
              options: {
                customBodyRender: (value: string) =>
                  value ? userEmails[value] : '—',
                setCellProps: () => flexibleWidthProps(isDesktop),
                setHeaderProps: () => flexibleWidthProps(isDesktop),
              },
            },
            {
              // Existing backend logic updates this field when the rate is modified;
              // it is not the original creation date of the contract rate
              name: 'createdAt',
              label: 'Last Modified At',
              options: {
                customBodyRender: renderDateTime,
                setCellProps: () => flexibleWidthProps(isDesktop),
                setHeaderProps: () => flexibleWidthProps(isDesktop),
              },
            },
            {
              name: 'rateInCents',
              label: 'Rate',
              options: {
                customBodyRender: (
                  rateInCents: ModifiableRateInCents | number
                ) =>
                  canEdit &&
                  modifiableContractRates &&
                  typeof rateInCents !== 'number' ? (
                    <RateWrapper>
                      <p>$</p>
                      <RateInput
                        inputMode="numeric"
                        value={dollarFormat(rateInCents.value)}
                        onSelect={(e) => {
                          const input = e.currentTarget;
                          setTimeout(() => {
                            input.setSelectionRange(12, 12);
                          }, 0);
                        }}
                        onChange={(e) => {
                          e.currentTarget.value.length < 11 &&
                            handleRateInputChange(
                              e,
                              rateInCents,
                              modifiableContractRates
                            );
                        }}
                        $touched={rateInCents.touched}
                        disabled={areContractRatesLoading}
                      ></RateInput>
                    </RateWrapper>
                  ) : (
                    typeof rateInCents === 'number' &&
                    `$${dollarFormat(rateInCents)}`
                  ),

                setCellProps: () => fixedWidthProps(isDesktop),
              },
            },
          ]}
          options={{
            selectableRows: 'none',
            search: false,
          }}
        />
      )}
    </>
  );
};
