import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsLoading } from 'state/system/slice';

import ArrowIcon from 'assets/arrow-right-line.svg?react';

import {
  Spacer,
  LoadingWrapper,
  StyledSavingText,
  Wrapper,
  ProgressBarWrapper,
  SavingSection,
  Message,
  StyledButton,
  CTAWrapper,
  Messages,
  LeftFloatingMessage,
  PROGRESSBAR_HEIGHT,
} from 'pages/Patient/Footer.css';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectActiveCase,
  selectPatient,
  selectIsSubmitted,
  selectSkipToCheckoutPage,
  selectAreDiagnosticMaterialsReadyForSubmit,
  selectAreTreatmentGoalsReadyForSubmit,
  uploadPhoto,
  uploadScan,
  uploadXrays,
  uploadIntakeForms,
  removeMaterial,
  selectIsRetainerCaseWithOrderInfo,
  selectAreUnsubmittedTreatmentGoalsValid,
} from 'pages/Patient/patientSlice';
import { Loading, ProgressBar } from '@candidco/enamel';

import CheckIcon from 'assets/check.svg?react';

import {
  getDiagnosticMaterialsPageInfo,
  getTreatmentGoalsPageInfo,
  getReviewAndSubmitPageInfo,
  getBasicInfoNextPageInfo,
  getCheckoutPageInfo,
  getSubmitCaseInfo,
} from 'pages/Patient/utils';
import { CaseTypeNames, ProviderFacingStates } from 'constants/Case';
import api from 'state/api';

export enum Steps {
  BasicInfo,
  DiagnosticMaterials,
  TreatmentGoals,
  Checkout,
}

const IsSaving = () => (
  <>
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
    <StyledSavingText>Saving changes</StyledSavingText>
  </>
);

const Saved = () => (
  <>
    <CheckIcon />
    <StyledSavingText>All changes auto-saved</StyledSavingText>
  </>
);
type ButtonState = {
  text: string | null;
  nextPage: string | null;
};

type ProgressBarProps = {
  total: number;
  current: number;
};

const CaseCreatorFooter = ({
  onSubmit,
  disabled = false,
  isCtaLoading = false,
  progressBarProps,
  rightMessage = '',
  currentStep = null,
}: {
  currentStep: Steps | null;
  disabled?: boolean;
  isCtaLoading?: boolean;
  progressBarProps?: ProgressBarProps;
  rightMessage?: string;
  onSubmit?: () => Promise<any> | void;
}) => {
  const { push } = useHistory();

  const patient = useSelector(selectPatient);
  const activeCase = useSelector(selectActiveCase);
  const isSubmitted = useSelector(selectIsSubmitted);
  const { 'case-creator-auto-save': enableAutoSave } = useFlags();
  const isUploadingPhoto = useIsLoading(uploadPhoto.typePrefix);
  const isUploadingScan = useIsLoading(uploadScan.typePrefix);
  const isUploadingXrays = useIsLoading(uploadXrays.typePrefix);
  const isUploadingIntake = useIsLoading(uploadIntakeForms.typePrefix);
  const isRemovingMaterial = useIsLoading(removeMaterial.typePrefix);
  const selectSkipCaseSummary = useSelector(selectSkipToCheckoutPage);
  const isRetainerCaseWithOrderInfo = useSelector(
    selectIsRetainerCaseWithOrderInfo
  );
  const { data: { edges: ordersForCase = [] } = {} } = api.useGetOrdersQuery({
    caseRef: activeCase?.caseRef,
  });

  const hasSubmittedBefore =
    isRetainerCaseWithOrderInfo ||
    (activeCase?.caseType.name === 'retainer' && ordersForCase.length > 0);
  const showAutoSaving =
    enableAutoSave &&
    (isUploadingPhoto ||
      isUploadingScan ||
      isUploadingXrays ||
      isRemovingMaterial ||
      isUploadingIntake);
  const areDiagnosticMaterialsComplete = useSelector(
    selectAreDiagnosticMaterialsReadyForSubmit
  );

  //If we're in diagnostic materials, treatment goals need to be submitted in order to be marked completed
  const areTreatmentGoalsComplete = useSelector(
    selectAreTreatmentGoalsReadyForSubmit
  );
  //But if we're in treatment goals, we will make a submission, so if the unsubmitted treatment goals are valid, we can consider them complete
  const areUnsubmittedTreatmentGoalsValid = useSelector(
    selectAreUnsubmittedTreatmentGoalsValid
  );

  const isCheckout = currentStep === Steps.Checkout;
  if (isSubmitted && !isCheckout) {
    return null;
  }

  //returns the text, and the action for the cta button
  const getNextState = (): ButtonState => {
    switch (currentStep) {
      case Steps.BasicInfo:
        return getBasicInfoNextPageInfo(
          patient?.id,
          areDiagnosticMaterialsComplete,
          areTreatmentGoalsComplete
        );
      case Steps.DiagnosticMaterials:
        //TODO: This flow should be unified betwen retainers and non retainers
        if (selectSkipCaseSummary) {
          if (hasSubmittedBefore) {
            // used to go back to the patient page after submittting here
            return getSubmitCaseInfo(patient?.id);
          }
          // hacking for now to assume we want to go to the checkout page

          return getCheckoutPageInfo(patient?.id);
        }
        if (
          activeCase?.caseState?.providerFacing ===
          ProviderFacingStates.ACTION_NEEDED
        ) {
          return getReviewAndSubmitPageInfo(patient?.id);
        }

        if (activeCase?.caseType.name == CaseTypeNames.RETAINER) {
          if (
            areTreatmentGoalsComplete &&
            areDiagnosticMaterialsComplete &&
            !hasSubmittedBefore
          ) {
            return getCheckoutPageInfo(patient?.id);
          } else if (!areTreatmentGoalsComplete!) {
            return getTreatmentGoalsPageInfo(patient?.id);
          } else {
            return getReviewAndSubmitPageInfo(patient?.id);
          }
        } else {
          return areTreatmentGoalsComplete
            ? getReviewAndSubmitPageInfo(patient?.id)
            : getTreatmentGoalsPageInfo(patient?.id);
        }
      case Steps.TreatmentGoals:
        if (activeCase?.caseType.name == CaseTypeNames.RETAINER) {
          if (
            areUnsubmittedTreatmentGoalsValid &&
            areDiagnosticMaterialsComplete &&
            !hasSubmittedBefore
          ) {
            return getCheckoutPageInfo(patient?.id);
          } else if (!areDiagnosticMaterialsComplete) {
            return getDiagnosticMaterialsPageInfo(patient?.id);
          } else {
            return getReviewAndSubmitPageInfo(patient?.id);
          }
        } else {
          return areDiagnosticMaterialsComplete
            ? getReviewAndSubmitPageInfo(patient?.id)
            : getDiagnosticMaterialsPageInfo(patient?.id);
        }
      case Steps.Checkout:
        return {
          text: 'Submit order',
          nextPage: null,
        };
      default:
        return {
          text: null,
          nextPage: null,
        };
    }
  };

  const { text, nextPage } = getNextState();

  const onClick = async () => {
    let success = true;
    if (onSubmit) {
      success = await onSubmit();
    }

    if (nextPage && success) {
      push(nextPage);
    }
  };

  const leftFloatingMessage =
    progressBarProps &&
    progressBarProps?.total &&
    currentStep === Steps.TreatmentGoals
      ? `${progressBarProps.current} of ${progressBarProps.total} questions answered`
      : '';

  return (
    <Spacer>
      {progressBarProps && (
        <ProgressBarWrapper>
          <ProgressBar
            style={{ height: PROGRESSBAR_HEIGHT, width: '100%' }}
            totalValue={progressBarProps.total}
            currentValue={progressBarProps.current}
          />
        </ProgressBarWrapper>
      )}
      <Wrapper>
        <CTAWrapper>
          <Messages>
            {enableAutoSave ? (
              <>
                {leftFloatingMessage && (
                  <LeftFloatingMessage>
                    {leftFloatingMessage}
                  </LeftFloatingMessage>
                )}
                <SavingSection>
                  {!isCheckout && (showAutoSaving ? <IsSaving /> : <Saved />)}
                </SavingSection>
              </>
            ) : (
              <Message>{leftFloatingMessage}</Message>
            )}
            <Message>{rightMessage}</Message>
          </Messages>
          <StyledButton
            disabled={disabled}
            onClick={onClick}
            buttonType="secondary"
            rightIcon={<ArrowIcon style={{ width: '1rem' }} />}
            isLoading={isCtaLoading}
          >
            {text ?? ''}
          </StyledButton>
        </CTAWrapper>
      </Wrapper>
    </Spacer>
  );
};

export default CaseCreatorFooter;
