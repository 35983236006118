import { colors } from '@candidco/enamel';
import { Accordion } from '@reach/accordion';
import { Form } from 'formik';
import styled from 'styled-components/macro';

export const StyledAccordion = styled(Accordion)`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 680px;
`;

export const ProblemCategoryHeader = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const ProblemCategorySubHeader = styled.div`
  font-size: 1rem;
`;

export const FieldContainer = styled.div`
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

export const SubLabel = styled.div`
  font-size: 16px;
  color: ${colors.black70};
  margin-bottom: 4px;
`;

export const AdditionalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const EscalationCategoryHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
