import React, { useState, useEffect, useCallback } from 'react';

import {
  Section,
  SectionTitle,
  SectionInputLabel,
  SectionInputLabelGroup,
  InputGroup,
  Checkbox,
  StyledButton,
  StyledFormikInputWrapper,
  StyledFormikSelectWrapper,
  ButtonGroup,
  Subheader,
  ButtonCounter,
  Border,
  ButtonGroupContainer,
  PresetValuesGroup,
  PresetGroup,
} from 'pages/AccountCreation/AccountCreation.css';
import { debounce } from 'lodash';

import { FormikOption } from 'components/FormikForms';
import {
  requiredValidator,
  validSfAccountId,
  usStateOptions,
  emailValidator,
} from 'components/FormikForms/utils';

import {
  AddressSection,
  IFormikContext,
  BillingAccountSection,
} from 'pages/AccountCreation/AccountCreationSubsections';
import { generateAccountPassword } from 'pages/AccountCreation/utils';
import {
  GetBillingAccountsDocument,
  GetBillingAccountsQuery,
  GetBillingAccountsQueryVariables,
  GetGenericAddressesDocument,
  GetGenericAddressesQuery,
  GetGenericAddressesQueryVariables,
  GetPracticesDocument,
  GetPracticesQuery,
  GetPracticesQueryVariables,
  GetPartnerOrgsDocument,
  GetPartnerOrgsQuery,
  GetPartnerOrgsQueryVariables,
  OrganizationTypes,
  CreatePracticeAccountMutationVariables,
} from 'generated/legacy/graphql';
import { useLazyQuery } from 'utils/lazyQuery';
import { FieldArray, useFormikContext } from 'formik';
import {
  GetLoyaltyProgramsDocument,
  GetLoyaltyProgramsQuery,
  GetLoyaltyProgramsQueryVariables,
} from 'generated/core/graphql';
import { useGQLQuery } from 'hooks/useGQL';
import { Tooltip } from '@candidco/enamel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PRACTICE_ORGANIZATION_TYPES, BRAND_NAMES } from 'constants/index';
import {
  GLIDEWELL_BRAND_LABEL,
  CANDID_BRAND_NAME,
  GLIDEWELL_BRAND_NAME,
} from 'constants/brands';
import AccountCreationAutofill from 'pages/AccountCreation/AccountCreationAutofill';

export const NewAccountFormV2 = ({
  submitButtonRef,
  submitForm,
  isSubmitting,
}: AccountFormProps) => {
  const {
    values: {
      practice: { organizationType },
    },
    isValid,
    //Diferentiating between the root submit that triggers the address validation, and the submit that triggers the form submission
    handleSubmit: rootSubmit,
  } = useFormikContext<CreatePracticeAccountMutationVariables>();
  const isManagingOrganization =
    organizationType === OrganizationTypes.ManagingOrganization;

  return (
    <div>
      <PracticeSection
        submitButtonRef={submitButtonRef}
        submitForm={submitForm}
        isSubmitting={isSubmitting}
      />
      {!isManagingOrganization && (
        <>
          <UserDoctorSection />
        </>
      )}
      <StyledButton
        disabled={!isValid || isSubmitting}
        isLoading={isSubmitting}
        onClick={() => rootSubmit()}
      >
        Create Account
      </StyledButton>
    </div>
  );
};

type AccountFormProps = {
  submitButtonRef: React.RefObject<HTMLButtonElement>;
  submitForm: (values: CreatePracticeAccountMutationVariables) => void;
  isSubmitting: boolean;
};

const PracticeSection = ({ submitButtonRef, submitForm }: AccountFormProps) => {
  const { 'white-labeling-brand-support': whiteLabeling } = useFlags();
  const [practices, setPractices] = useState<FormikOption[]>([]);
  const [partnerOrgs, setPartnerOrgs] = useState<FormikOption[]>([]);
  const [isCreateNewAddress, setIsCreateNewAddress] = useState<boolean>(false);
  const [isCreatingNewBillingAccount, setIsCreatingNewBillingAccount] =
    useState<boolean>(false);
  const [addresses, setAddresses] = useState<FormikOption[]>([]);
  const [billingAccounts, setBillingAccounts] = useState<FormikOption[]>([]);
  const [partnerOrgbillingAccounts, setPartnerOrgBillingAccounts] = useState<
    FormikOption[]
  >([]);
  const contractRate = 1499.0;

  const { values, setFieldValue }: IFormikContext = useFormikContext();
  const {
    practice: { defaultStripeUserId, organizationType, loyaltyProgramId },
  } = values;
  const hasPartnerOrg = values?.practice?.partnerOrgName;

  const practiceQuery = useLazyQuery<
    GetPracticesQuery,
    GetPracticesQueryVariables
  >(GetPracticesDocument);
  const partnerOrgsQuery = useLazyQuery<
    GetPartnerOrgsQuery,
    GetPartnerOrgsQueryVariables
  >(GetPartnerOrgsDocument);
  const genericAddressesQuery = useLazyQuery<
    GetGenericAddressesQuery,
    GetGenericAddressesQueryVariables
  >(GetGenericAddressesDocument);
  const billingAccountsQuery = useLazyQuery<
    GetBillingAccountsQuery,
    GetBillingAccountsQueryVariables
  >(GetBillingAccountsDocument);

  const [getLoyaltyProgramData, { data: getLoyaltyProgramResult }] =
    useGQLQuery<GetLoyaltyProgramsQuery, GetLoyaltyProgramsQueryVariables>(
      GetLoyaltyProgramsDocument
    );
  const loyaltyPrograms: FormikOption[] = (
    getLoyaltyProgramResult?.getLoyaltyPrograms ?? []
  ).map((loyaltyProgram) => ({
    value: loyaltyProgram.id,
    displayValue: loyaltyProgram.name,
  }));

  const formatBillingAccountData = (
    accountId: string,
    accountName: string,
    email: string,
    contactNumber: string
  ) => {
    return {
      value: accountId,
      displayValue: `${accountName}, ${email}, ${contactNumber}`,
    };
  };

  useEffect(() => {
    const fetchPractices = async () => {
      const data = await practiceQuery({
        organizationType: OrganizationTypes.ManagingOrganization,
      });
      setPractices(
        data?.data?.practices?.map((practice) => ({
          value: practice.id,
          displayValue: practice.name,
        })) || []
      );
    };

    const fetchPartnerOrgs = async () => {
      const { data } = await partnerOrgsQuery({});

      const partnerOrgsOptions: FormikOption[] = [];
      const partnerOrgBillingAccountOptions: FormikOption[] = [];

      data.getPartnerOrgs
        ?.filter((partnerOrg) => !!partnerOrg)
        .forEach((partnerOrg) => {
          partnerOrgsOptions.push({
            value: partnerOrg.name,
            displayValue: partnerOrg.name,
          });
          if (!partnerOrg.billingAccount) {
            return;
          }
          partnerOrgBillingAccountOptions.push(
            formatBillingAccountData(
              partnerOrg.billingAccount?.id as string,
              partnerOrg.billingAccount!.billingContact.name,
              partnerOrg.billingAccount!.billingContact.email,
              partnerOrg.billingAccount!.billingContact.primaryContactNumber!
            )
          );
        });

      setPartnerOrgs(partnerOrgsOptions);
      setPartnerOrgBillingAccounts(partnerOrgBillingAccountOptions);
    };
    getLoyaltyProgramData({});
    fetchPractices();
    fetchPartnerOrgs();
  }, []);

  useEffect(() => {
    if (values.practice.partnerOrgName) {
      // All partner orgs should be auto assigned to the grandfathered loyalty program
      const grandfatheredProgramID = loyaltyPrograms.find((loyaltyProgram) =>
        loyaltyProgram.displayValue.includes('Grandfathered')
      )?.value;
      setFieldValue('practice.loyaltyProgramId', grandfatheredProgramID);

      setBillingAccounts(partnerOrgbillingAccounts);
      const partnerOrgBillingAccount = partnerOrgbillingAccounts.find(
        (partnerOrgbillingAccount) =>
          partnerOrgbillingAccount.displayValue.includes(
            values.practice.partnerOrgName!
          )
      );

      setFieldValue(
        'practice.billingAccountId',
        partnerOrgBillingAccount!.value
      );
    } else {
      setFieldValue('practice.billingAccountId', undefined);
      setBillingAccounts([]);
    }
  }, [values.practice.partnerOrgName]);

  const fetchAddresses = async (zipCodeSearch: string = '') => {
    if (zipCodeSearch === '') {
      return;
    }
    const data = await genericAddressesQuery({ zip: zipCodeSearch });
    setAddresses(
      data.data.genericAddress.map((genericAddress) => ({
        value: genericAddress?.id as string,
        displayValue: `${genericAddress?.name as string}, 
        ${genericAddress.addressLine1}, 
        ${genericAddress.addressLine2 ? genericAddress?.addressLine2 : ''}, 
        ${genericAddress.city}, 
        ${genericAddress.stateCode}, 
        ${genericAddress.countryCode}, 
        ${genericAddress.zip}`,
      })) || []
    );
  };
  const fetchBillingAccounts = async (name: string = '') => {
    if (name === '') {
      return;
    }
    const data = await billingAccountsQuery({ name });
    const billingAccountData: FormikOption[] = [];
    data.data.billingAccount.forEach((billingAccount) => {
      billingAccountData.push(
        formatBillingAccountData(
          billingAccount.id as string,
          billingAccount.billingContact.name,
          billingAccount.billingContact.email,
          billingAccount.billingContact.primaryContactNumber!
        )
      );
    });
    setBillingAccounts(billingAccountData);
  };

  const debouncedFetchAddresses = useCallback(
    debounce(fetchAddresses, 300),
    []
  );
  const debouncedFetchBillingAccounts = useCallback(
    debounce(fetchBillingAccounts, 300),
    []
  );

  let loyaltyTiers: FormikOption[] = [];

  if (loyaltyProgramId) {
    loyaltyTiers =
      getLoyaltyProgramResult?.getLoyaltyPrograms
        .find((loyaltyProgram) => loyaltyProgram.id === loyaltyProgramId)
        ?.loyaltyProgramTiers.map((loyaltyProgramTier) => ({
          value: loyaltyProgramTier.id,
          displayValue: loyaltyProgramTier.name,
        })) || [];

    // All practices with partner orgs should be auto assigned to the Standard tier
    if (values?.practice?.partnerOrgName && !values?.practice?.loyaltyTierId) {
      const standardTierId =
        loyaltyTiers.find((loyaltyTier) =>
          loyaltyTier.displayValue.includes('Standard')
        )?.value || undefined;
      setFieldValue('practice.loyaltyTierId', standardTierId);
    }
  }

  useEffect(() => {
    if (isCreateNewAddress) {
      setFieldValue('practice.addressId', undefined);
      setFieldValue('practice.address.phone', '+1');
      setFieldValue('practice.address.addressLine1', '');
    } else {
      setFieldValue('practice.address', undefined);
    }
  }, [isCreateNewAddress]);

  const isManagingOrganization =
    organizationType === OrganizationTypes.ManagingOrganization;
  useEffect(() => {
    if (isManagingOrganization) {
      setFieldValue('user', undefined);
      setFieldValue('doctor', undefined);
      setFieldValue('practice.parentOrganizationId', undefined);
    }
  }, [isCreateNewAddress, organizationType]);

  useEffect(() => {
    if (isCreatingNewBillingAccount) {
      setFieldValue('practice.billingAccountId', undefined);
      setFieldValue(
        'practice.billingAccount.alignersContractRate',
        contractRate
      );
      setFieldValue('practice.billingAccount.whiteningContractRate', 149.0);
      setFieldValue(
        'practice.billingAccount.billingContact.primaryContactNumber',
        '+1'
      );
    } else {
      setFieldValue('practice.billingAccount', undefined);
    }
  }, [isCreatingNewBillingAccount]);

  const setPartnerOrgBaseOnBrand = (e: FormikOption) => {
    if (e.value === GLIDEWELL_BRAND_NAME) {
      setFieldValue('practice.partnerOrgName', GLIDEWELL_BRAND_LABEL);
    } else {
      setFieldValue('practice.partnerOrgName', undefined);
    }
  };

  const setBrandBaseOnPartnerOrg = (e: FormikOption) => {
    if (e.value === GLIDEWELL_BRAND_LABEL) {
      setFieldValue('practice.brandName', GLIDEWELL_BRAND_NAME);
    } else {
      setFieldValue('practice.brandName', CANDID_BRAND_NAME);
    }
  };

  const createNewAddress = useCallback(() => {
    if (!isCreateNewAddress) {
      setIsCreateNewAddress(true);
    }
  }, []);

  const createNewBilling = useCallback(() => {
    if (!isCreatingNewBillingAccount) {
      setIsCreatingNewBillingAccount(true);
    }
  }, []);

  return (
    <Section>
      <SectionTitle>Create New Practice</SectionTitle>

      <AccountCreationAutofill
        createNewAddress={createNewAddress}
        createNewBilling={createNewBilling}
        setFieldValue={setFieldValue}
      />
      <SectionInputLabelGroup>
        {whiteLabeling && (
          <StyledFormikSelectWrapper
            type="select"
            testId="input-practice-brand"
            label="Brand*"
            options={BRAND_NAMES}
            name="practice.brandName"
            validate={requiredValidator}
            onSelectChange={(e) => whiteLabeling && setPartnerOrgBaseOnBrand(e)}
          />
        )}
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <StyledFormikInputWrapper
          testId="input-name"
          label="Practice Name*"
          name="practice.name"
          type="text"
          validate={requiredValidator}
        />
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <SectionInputLabel>Default Stripe User Id</SectionInputLabel>
        {defaultStripeUserId}
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <StyledFormikSelectWrapper
          type="select"
          testId="input-practice-organization-type"
          label="Practice Organization Type*"
          options={PRACTICE_ORGANIZATION_TYPES}
          name="practice.organizationType"
          validate={requiredValidator}
        />
      </SectionInputLabelGroup>
      {!isManagingOrganization && (
        <SectionInputLabelGroup>
          <StyledFormikSelectWrapper
            type="select"
            testId="input-parent-organization"
            label="Parent Organization"
            options={practices}
            name="practice.parentOrganizationId"
          />
        </SectionInputLabelGroup>
      )}
      <SectionInputLabelGroup>
        {!isManagingOrganization && (
          <>
            <StyledFormikInputWrapper
              testId="input-practice-child-salesforce-id"
              label={
                <SectionInputLabel>
                  Child Account Salesforce ID*
                  <Tooltip title="You can find the Salesforce ID in the URL Bar when viewing the child account." />
                </SectionInputLabel>
              }
              name="practice.salesforceChildAccountId"
              type="text"
              validate={validSfAccountId}
            />
          </>
        )}
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <SectionInputLabel>
          <label>Address*</label>
          <div>
            Create new Address? &nbsp;
            <Checkbox
              name="create-address"
              type="checkbox"
              checked={isCreateNewAddress}
              id="create-address"
              onClick={() => setIsCreateNewAddress(!isCreateNewAddress)}
            />
          </div>
        </SectionInputLabel>
        {isCreateNewAddress ? (
          <AddressSection
            submitButtonRef={submitButtonRef}
            submitForm={submitForm}
          />
        ) : (
          <InputGroup>
            <StyledFormikSelectWrapper
              type="select"
              testId="input-address-id"
              placeholder="Search Address by zipcode"
              onInputChange={(input: string) => {
                debouncedFetchAddresses(input);
              }}
              options={addresses}
              name="practice.addressId"
            />
          </InputGroup>
        )}
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        <StyledFormikSelectWrapper
          type="select"
          testId="input-partner-organization"
          label="Partner Organization"
          options={partnerOrgs}
          name="practice.partnerOrgName"
          placeholder="None"
          isClearable
          onSelectChange={(e) => {
            whiteLabeling && setBrandBaseOnPartnerOrg(e);
          }}
          onClear={() =>
            whiteLabeling &&
            setFieldValue('practice.brandName', CANDID_BRAND_NAME)
          }
        />
        {hasPartnerOrg && (
          <StyledFormikInputWrapper
            testId="input-practice-partner-org-external-id"
            label="Partner Org External ID*"
            name="practice.partnerOrgAccountExternalId"
            type="text"
          />
        )}
      </SectionInputLabelGroup>
      <SectionInputLabelGroup>
        {!hasPartnerOrg && (
          <SectionInputLabel>
            <label>Billing Account*</label>
            <div>
              Create new Billing Account? &nbsp;
              <Checkbox
                name="create-billing-account"
                type="checkbox"
                checked={isCreatingNewBillingAccount}
                id="create-billing-account"
                onClick={() =>
                  setIsCreatingNewBillingAccount(!isCreatingNewBillingAccount)
                }
              />
            </div>
          </SectionInputLabel>
        )}

        {isCreatingNewBillingAccount ? (
          <BillingAccountSection
            grandfatheredProgramID={
              loyaltyPrograms?.find((loyaltyProgram) =>
                loyaltyProgram.displayValue.includes('Grandfathered')
              )?.value
            }
            dsoProgramID={
              loyaltyPrograms?.find((loyaltyProgram) =>
                loyaltyProgram.displayValue.includes('DSO')
              )?.value
            }
          />
        ) : (
          <InputGroup>
            <StyledFormikSelectWrapper
              type="select"
              testId="input-billing-account-id"
              placeholder="Search by Billing Contact name"
              onInputChange={(input: string) => {
                debouncedFetchBillingAccounts(input);
              }}
              options={billingAccounts}
              name="practice.billingAccountId"
              disabled={!!hasPartnerOrg}
            />
          </InputGroup>
        )}
      </SectionInputLabelGroup>

      {!isManagingOrganization && (
        <>
          <SectionInputLabelGroup>
            <StyledFormikSelectWrapper
              type="select"
              testId="input-loyalty-program"
              label="Loyalty Program*"
              options={loyaltyPrograms}
              disabled={!!hasPartnerOrg}
              validate={requiredValidator}
              name="practice.loyaltyProgramId"
            />
          </SectionInputLabelGroup>

          <SectionInputLabelGroup>
            <StyledFormikSelectWrapper
              type="select"
              testId="input-loyalty-tier"
              label="Loyalty Program Tier"
              disabled={!loyaltyProgramId || !!hasPartnerOrg}
              options={loyaltyTiers}
              name="practice.loyaltyTierId"
            />
          </SectionInputLabelGroup>
        </>
      )}
    </Section>
  );
};

const UserDoctorSection = () => {
  const { values, setFieldValue }: IFormikContext = useFormikContext();

  const [numberOfUsers, setNumberOfUsers] = useState<number>(1);

  useEffect(() => {
    [...Array(numberOfUsers)].forEach((_, index) => {
      setFieldValue(
        `userDoctors[${index}].password`,
        generateAccountPassword({
          brandName: values.practice.brandName,
          practiceName: values.practice.name,
        })
      );
    });
  }, [
    values.practice?.name,
    values.practice?.brandName,
    setFieldValue,
    numberOfUsers,
  ]);

  function getSubheader() {
    if (numberOfUsers === 0) {
      return (
        <>
          When creating an Office Location with 0 users & doctors, be sure to
          assign an existing user & doctor to this practice after it is created
          on API.
        </>
      );
    } else {
      return (
        <>
          Creating {numberOfUsers}{' '}
          {numberOfUsers > 1 ? 'users and doctors' : 'user and doctor'}
        </>
      );
    }
  }

  return (
    <Section>
      <FieldArray
        name="userDoctors"
        render={(arrayHelpers) => (
          <>
            <SectionTitle>
              User / Doctor
              <Subheader>{getSubheader()}</Subheader>
            </SectionTitle>

            {[...Array(numberOfUsers)].map((_, index) => (
              <>
                <ButtonGroupContainer>
                  <ButtonGroup>
                    <ButtonCounter
                      isPositive={false}
                      onClick={() => {
                        arrayHelpers.remove(index);
                        setNumberOfUsers(numberOfUsers - 1);
                      }}
                    >
                      -
                    </ButtonCounter>
                  </ButtonGroup>
                </ButtonGroupContainer>

                <SectionInputLabelGroup>
                  <StyledFormikInputWrapper
                    testId="input-email"
                    label="User Email*"
                    name={`userDoctors[${index}].email`}
                    type="text"
                    validate={emailValidator}
                  />
                </SectionInputLabelGroup>
                <SectionInputLabelGroup>
                  <StyledFormikInputWrapper
                    testId="input-password"
                    label="User Password*"
                    name={`userDoctors[${index}].password`}
                    type="text"
                    disabled
                    validate={requiredValidator}
                  />
                </SectionInputLabelGroup>
                <SectionInputLabelGroup>
                  <StyledFormikInputWrapper
                    testId="input-full-name"
                    label="Full name*"
                    name={`userDoctors[${index}].fullName`}
                    type="text"
                    validate={requiredValidator}
                  />
                </SectionInputLabelGroup>
                <SectionInputLabelGroup>
                  <StyledFormikSelectWrapper
                    type="select"
                    label="Licensed In"
                    testId="input-licensed-in"
                    placeholder="Doctor Licensed In"
                    options={usStateOptions}
                    name={`userDoctors[${index}].licensedIn`}
                    multiple
                  />
                </SectionInputLabelGroup>
                <PresetValuesGroup>
                  <PresetGroup>
                    <SectionInputLabel>Groups</SectionInputLabel>
                    candid-pro
                  </PresetGroup>
                  <PresetGroup>
                    <SectionInputLabel>
                      Organization Access Level
                    </SectionInputLabel>
                    Viewer
                  </PresetGroup>
                  <PresetGroup>
                    <SectionInputLabel>
                      User Managed Locations
                    </SectionInputLabel>
                    Viewer (for the practice above)
                  </PresetGroup>
                  <PresetGroup>
                    <SectionInputLabel>Specialty</SectionInputLabel>
                    dentist
                  </PresetGroup>
                </PresetValuesGroup>
                <Border />
              </>
            ))}
            <ButtonGroupContainer>
              <ButtonGroup>
                <ButtonCounter
                  isPositive
                  onClick={() => setNumberOfUsers(numberOfUsers + 1)}
                >
                  +
                </ButtonCounter>
              </ButtonGroup>
            </ButtonGroupContainer>
          </>
        )}
      />
    </Section>
  );
};
