import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'state/store';
import {
  fetchPracticePaymentStatus,
  selectPracticePaymentStatus,
  fetchPracticesByName,
  selectPractices,
} from 'state/commerce/slice';
import {
  CustomerReactSelectStyles,
  StyledReactSelect,
} from 'styles/inputs.css';
import { ReactSelectOption } from 'utils/types';
import { PageSection } from 'styles/layout.css';
import {
  Flex,
  LightText,
  StyledBalanceBox,
  StyledChip,
  StyledTable,
} from 'pages/BillingManagement/Invoices/styles';
import { Button, colors, Loading } from '@candidco/enamel';
import { BillingYaypayCustomerInvoiceStatusChoices } from 'generated/core/graphql';
import { TabButton, TabsContainer } from 'components/Tabs/Tabs.css';
import { AuthContext } from 'components/AuthProvider';

export const PracticeInvoices = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { userInfo } = useContext(AuthContext);
  const practices = useSelector(selectPractices);

  const [selectedPractice, setSelectedPractice] =
    useState<ReactSelectOption<number>>();
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] =
    useState<BillingYaypayCustomerInvoiceStatusChoices>(
      BillingYaypayCustomerInvoiceStatusChoices.Unpaid
    );
  const [practiceOptions, setPracticeOptions] = useState<
    ReactSelectOption<number>[]
  >([]);
  const practicePaymentStatus = useSelector(
    selectPracticePaymentStatus(selectedPractice?.value, selectedInvoiceStatus)
  );
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    dispatch(fetchPracticesByName(searchValue));
  }, [searchValue]);
  // Ideally move this to a selector + redux
  useEffect(() => {
    // Enable searching on all practices if user is staff

    if (userInfo?.isStaff && practices.length) {
      setPracticeOptions(
        practices.map((p) => ({ value: parseInt(p.id), label: p.name }))
      );
    } else if (userInfo?.doctor?.practices) {
      setPracticeOptions(
        [...(userInfo?.doctor?.practices || {})] //need to copy to sort
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((p) => {
            return { value: parseInt(p.id), label: p.name };
          })
      );
    }
  }, [userInfo, practices]);

  useEffect(() => {
    if (!selectedPractice) {
      setSelectedPractice(practiceOptions[0]);
    }
  }, [practiceOptions]);

  useEffect(() => {
    if (selectedPractice?.value) {
      dispatch(fetchPracticePaymentStatus(selectedPractice.value.toString()));
    }
  }, [selectedPractice?.value]);

  const columns = [
    { label: 'Due Date', name: 'dueDate' },
    { label: 'Invoice #', name: 'invoiceId' },
    { label: 'Amount Due', name: 'balanceDue' },
  ];

  const options = {
    search: false,
    paging: false,
    sorting: false,
    draggable: false,
    actionsColumnIndex: -1,
    selectableRows: 'none',
  };

  const mappedInvoices = practicePaymentStatus?.invoices.map((invoice) => ({
    dueDate: invoice.dueDate,
    invoiceId: invoice.invoiceId,
    balanceDue: invoice.balanceDue,
  }));

  return (
    <PageSection isNarrow>
      <Flex style={{ gap: '32px' }} direction="column">
        <Flex style={{ justifyContent: 'space-between' }}>
          <Flex direction="column">
            <h2>Invoices</h2>
            <LightText
              style={{
                color: colors.black70,
              }}
            >
              Invoices must be paid within 30 days from the date received
            </LightText>
          </Flex>
          <Flex style={{ height: 'max-content' }}>
            <Button
              buttonType="secondary"
              disabled={!practicePaymentStatus?.portalLink}
              onClick={() => window.open(practicePaymentStatus?.portalLink)}
            >
              {userInfo?.isStaff ? 'View in Yaypay' : 'Pay Balance'}
            </Button>
          </Flex>
        </Flex>
        {practiceOptions.length > 0 &&
          (searchValue !== '' || practiceOptions.length !== 1) && (
            <Flex style={{ width: '50%', zIndex: '999' }}>
              <StyledReactSelect
                placeholder={
                  userInfo?.isStaff
                    ? 'Enter practice name'
                    : 'Select a Practice'
                }
                styles={CustomerReactSelectStyles}
                options={practiceOptions}
                isDisabled={!practiceOptions}
                onChange={(value: ReactSelectOption<number>) =>
                  setSelectedPractice(value)
                }
                value={selectedPractice}
                onInputChange={(inputValue: string) =>
                  setSearchValue(inputValue)
                }
              />
            </Flex>
          )}
        {selectedPractice ? (
          <>
            <BalanceOverview
              unpaidBalance={practicePaymentStatus?.balance}
              pastDueBalance={practicePaymentStatus?.balancePastDue}
              pastDueBalanceDaysLate={practicePaymentStatus?.daysLate}
              availableCredit={0}
            />
            <Flex direction="column">
              <TabsContainer>
                <TabButton
                  isSelected={
                    selectedInvoiceStatus ===
                    BillingYaypayCustomerInvoiceStatusChoices.Unpaid
                  }
                  onClick={() =>
                    setSelectedInvoiceStatus(
                      BillingYaypayCustomerInvoiceStatusChoices.Unpaid
                    )
                  }
                >
                  Open invoice
                </TabButton>
                <TabButton
                  isSelected={
                    selectedInvoiceStatus ===
                    BillingYaypayCustomerInvoiceStatusChoices.Paid
                  }
                  onClick={() =>
                    setSelectedInvoiceStatus(
                      BillingYaypayCustomerInvoiceStatusChoices.Paid
                    )
                  }
                >
                  Closed invoices
                </TabButton>
              </TabsContainer>
              <StyledTable
                data={mappedInvoices}
                columns={columns}
                options={options}
              />
            </Flex>
          </>
        ) : (
          <Flex style={{ justifyContent: 'center', marginTop: '2rem' }}>
            <Loading />
          </Flex>
        )}
      </Flex>
    </PageSection>
  );
};

const BalanceOverview = ({
  unpaidBalance,
  pastDueBalance,
  pastDueBalanceDaysLate,
  availableCredit,
}: {
  unpaidBalance?: number;
  pastDueBalance?: number;
  pastDueBalanceDaysLate?: number | null;
  availableCredit?: number;
}) => {
  return (
    <Flex style={{ justifyContent: 'space-between', gap: '2rem' }}>
      <BalanceBox
        title="Unpaid Balance"
        amount={unpaidBalance ? unpaidBalance : 0}
      />
      <BalanceBox
        title="Past due Balance"
        amount={pastDueBalance ? pastDueBalance : 0}
        isError={!!pastDueBalance}
        errorText={`${pastDueBalanceDaysLate} days`}
      />
      <BalanceBox title="Available Credit" amount={availableCredit} />
    </Flex>
  );
};

const BalanceBox = ({
  title,
  amount,
  errorText,
  isError = false,
}: {
  title: string;
  amount?: number;
  isError?: boolean;
  errorText?: string;
}) => {
  return (
    <StyledBalanceBox direction="column">
      <LightText>{title}</LightText>
      <Flex style={{ justifyContent: 'space-between' }}>
        <h2>${amount?.toFixed(2)}</h2>
        {isError && <StyledChip label={errorText} />}
      </Flex>
    </StyledBalanceBox>
  );
};
