import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, NotificationContext } from '@candidco/enamel';
import * as Sentry from '@sentry/react';

import {
  CaseTypes,
  CreationChannels,
  useCreateCaseMutation,
  useTransitionJourneyMutation,
} from 'generated/legacy/graphql';

import {
  cancelCoreCase,
  selectLatestCaseWithApprovedTp,
  fetchMostRecentlyApprovedTpStagingForPatient,
  selectStepCountFromMostRecentlyApprovedTpStaging,
} from 'pages/Patient/patientSlice';
import { JourneyTransition } from 'pages/Case/types';

import { ALIGNER_JOURNEY_TYPE } from 'pages/Case/CaseProvider';
import {
  StyledAlertCard,
  LabelAndInputGroup,
  StyledModal,
  StyledDivider,
  Header,
  Body,
  InputFields,
  RadioGroup,
  StyledSelectInput,
  TextArea,
  AnswerRow,
  Question,
  FreeRefinementWrapper,
  ReplacementCopy,
  MonitoringLink,
  StyledThumbsUpIcon,
  AlertWrapper,
  StyledLinkButton,
  CheckboxInput,
  RowContainer,
} from 'components/Modals/RefinementsModal.css';
import FullPageModal from 'components/FullPageModal/FullPageModal';
import {
  selectAppliedRefinementDiscountType,
  RefinementType,
} from 'pages/Promotion/promotionsSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBrandDomainSettings } from 'utils/brands';
import CancelCaseInProgressModal, {
  CaseCloseType,
} from 'components/Modals/CaseInProgressModal';
import { CheckboxLabel } from 'styles/inputs.css';
import { GetCasesQuery, MaterialEvaluationTypes } from 'generated/core/graphql';
import { CaseTypeNames } from 'constants/Case';

type RefinementModalProps = {
  isOpen: boolean;
  caseRef?: string;
  activeCaseRef: string;
  activeCaseType: string;
  patientId: string;
  candidMonitoringLink: string;
  patientName: string;
  onSubmitted: () => void;
  onCancelClicked: () => void;
  formLink: string;
  jumpToQualityTicketModal?: () => void;
  cases: GetCasesQuery['getCases'];
};

type Option = {
  label: string;
  value: number;
};

const INITIAL_FIT_ISSUE_STEP_LIMIT = 2;

const FreeRefinementsBanner = () => (
  <FreeRefinementWrapper>
    <StyledThumbsUpIcon />
    <div>This case qualifies for free refinements</div>
  </FreeRefinementWrapper>
);

export const RefinementsModal = ({
  isOpen,
  activeCaseRef,
  activeCaseType,
  patientId,
  candidMonitoringLink,
  patientName,
  onCancelClicked,
  onSubmitted,
  formLink,
  jumpToQualityTicketModal,
  cases,
}: RefinementModalProps) => {
  const refinementPolicyCoupon = useSelector(
    selectAppliedRefinementDiscountType
  );
  const [startedTreatment, setStartedTreatment] = useState<boolean | null>(
    null
  );
  const [selectedStep, setSelectedStep] = useState<number | null>(null);
  const [stepOptions, setStepOptions] = useState<Option[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);
  const [ifiHasBeenReported, setIfiHasBeenReported] = useState(false);
  const [initialFitIssueDescription, setInitialFitIssueDescription] =
    useState<string>('');
  const { monitoringLabel } = getBrandDomainSettings();

  const latestCaseWithApprovedTp = useSelector(selectLatestCaseWithApprovedTp);
  const treatmentPlanSteps = useSelector(
    selectStepCountFromMostRecentlyApprovedTpStaging
  );
  const dispatch = useDispatch();
  const {
    'enforce-refinements-modal-redirect': enforceRefinementsModalRedirect,
  } = useFlags();

  useEffect(() => {
    if (patientId) {
      dispatch(fetchMostRecentlyApprovedTpStagingForPatient(Number(patientId)));
    }
  }, [patientId]);

  const patientIsWithinIFIStepLimit =
    startedTreatment &&
    selectedStep !== null &&
    selectedStep <= INITIAL_FIT_ISSUE_STEP_LIMIT;
  const isInititalFitIssue =
    startedTreatment === false || patientIsWithinIFIStepLimit;
  const { showNotification } = useContext(NotificationContext);
  const { 'bypass-ortho-review-process': bypassOrthoReviewProcessFlag } =
    useFlags();

  useEffect(() => {
    if (isOpen) {
      setShowConfirmationModal(true);
    }
  }, [isOpen]);

  const [createRefinementsCase, { loading: isCreatingCase }] =
    useCreateCaseMutation({
      onCompleted: () => {
        onSubmitted();
      },
    });
  const isFreeRefinement =
    refinementPolicyCoupon === RefinementType.Free ||
    refinementPolicyCoupon === RefinementType.Ifi;

  const [transitionJourney, { loading: isTransistionJourneySubmitting }] =
    useTransitionJourneyMutation();

  const handleSubmit = () => {
    let itiType = '';
    if (isInititalFitIssue) {
      itiType = 'initial_fit_issue';
    } else if (selectedStep && selectedStep === treatmentPlanSteps) {
      itiType = 'end_of_treatment_refinement';
    } else if (
      selectedStep &&
      treatmentPlanSteps &&
      selectedStep < treatmentPlanSteps
    ) {
      itiType = 'mid_course_correction';
    }

    const createCaseData = {
      itiType,
      currentStep: startedTreatment ? selectedStep : 0,
      notes: !startedTreatment ? initialFitIssueDescription : null,
    };

    //Filter out retainer cases, since they will never have evaluation requirements
    const existingCaseUsedBypassOrtho = cases
      .filter(
        (c) =>
          c.caseType.name === CaseTypeNames.ALIGNER ||
          c.caseType.name === CaseTypeNames.REFINEMENTS
      )
      //Next find if there are any cases that have do not ortho review requirements
      .some((c) =>
        c.workflow?.productionRequirements.some((pr) =>
          //Check that none of the requirements are ortho evaluations
          //If that is true, this case is a bypass ortho
          pr.evaluationRequirements.every(
            (er) =>
              er?.evaluationType !==
              MaterialEvaluationTypes.OrthodonticEvaluation
          )
        )
      );

    async function handleCaseTransition() {
      try {
        if (activeCaseType === 'retainer') {
          await dispatch(
            cancelCoreCase({
              caseRef: activeCaseRef,
              reason: isInititalFitIssue ? 'Initial fit issue' : 'Refinements',
            })
          );
        } else {
          await transitionJourney({
            variables: {
              caseRef: activeCaseRef,
              component: ALIGNER_JOURNEY_TYPE,
              transition: isInititalFitIssue
                ? JourneyTransition.ForceInitialFitIssue
                : JourneyTransition.ForceRefinementsNeeded,
              transitionReason: isInititalFitIssue
                ? 'Initial fit issue'
                : 'Refinements',
            },
          });
        }

        //Bypass ortho is the flag is set, OR if a previous case was bypass ortho
        const bypassOrthoReviewProcess =
          bypassOrthoReviewProcessFlag || existingCaseUsedBypassOrtho;

        // After cancelCoreCase or transitionJourney completes, run createRefinementsCase
        await createRefinementsCase({
          variables: {
            caseType: CaseTypes.ProAdditionalAligners,
            customerId: patientId,
            journey: CreationChannels.ProProvider,
            precedingCaseId: latestCaseWithApprovedTp?.data?.caseId ?? 0,
            data: {
              ...createCaseData,
              isGen2: true,
              requireOrthoReview: !bypassOrthoReviewProcess,
            },
          },
        });
      } catch (err) {
        if (!(err instanceof Error)) {
          throw err;
        }
        Sentry.captureException(err.message);
        showNotification(
          'Something went wrong, please try again in a few moments. If the issue persists, please contact support.'
        );
        console.error(err);
      }
    }
    handleCaseTransition();
  };

  useEffect(() => {
    if (treatmentPlanSteps) {
      setStepOptions(
        Array.from({ length: treatmentPlanSteps }).map((_val, idx) => {
          return {
            label:
              idx === treatmentPlanSteps - 1
                ? 'Step ' + (idx + 1) + ' (end of treatment)'
                : 'Step ' + (idx + 1),
            value: idx + 1,
          };
        })
      );
    }
  }, [treatmentPlanSteps]);

  const isInitialFitFormValid =
    !startedTreatment && !!initialFitIssueDescription;
  const isStartedTreatmentFormValid =
    startedTreatment &&
    selectedStep &&
    (selectedStep > INITIAL_FIT_ISSUE_STEP_LIMIT || initialFitIssueDescription);

  const isSubmitDisabled =
    startedTreatment === null ||
    (enforceRefinementsModalRedirect &&
      selectedStep &&
      selectedStep <= INITIAL_FIT_ISSUE_STEP_LIMIT) ||
    (!isInitialFitFormValid && !isStartedTreatmentFormValid);

  return (
    <FullPageModal
      isOpen={isOpen}
      patientName={patientName}
      titleText="Refinements"
      onClose={onCancelClicked}
      onContinue={handleSubmit}
      continueDisabled={isSubmitDisabled}
      footerLeftContent={isFreeRefinement && <FreeRefinementsBanner />}
      hasContinueArrow
      isLoading={isTransistionJourneySubmitting || isCreatingCase}
    >
      {/*
       * Note if we're coming from refinements, we'll always consider the previous case as completed
       */}
      <CancelCaseInProgressModal
        isOpen={showConfirmationModal}
        onClose={() => {
          onCancelClicked();
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          setShowConfirmationModal(false);
        }}
        completionType={CaseCloseType.Complete}
      />
      <StyledModal>
        <Header>Treatment progress</Header>
        <StyledDivider />
        <Body>
          <RadioGroup>
            <Question>
              Has this patient started treatment with the current set of
              aligners?
            </Question>
            <AnswerRow>
              <Button
                buttonType={
                  startedTreatment ? 'secondary' : 'secondary-outline'
                }
                onClick={() => setStartedTreatment(true)}
              >
                Yes
              </Button>
              <Button
                buttonType={
                  startedTreatment === false ? 'secondary' : 'secondary-outline'
                }
                onClick={() => setStartedTreatment(false)}
              >
                No
              </Button>
            </AnswerRow>
          </RadioGroup>
          {startedTreatment && (
            <InputFields>
              <LabelAndInputGroup>
                <Question style={{ marginBottom: 0 }}>
                  Which aligner step is this patient on?
                </Question>
                <MonitoringLink
                  href={candidMonitoringLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {`Confirm in ${monitoringLabel}`}
                </MonitoringLink>
                <StyledSelectInput
                  maxMenuHeight={200}
                  options={stepOptions}
                  onChange={(option: Option) => {
                    setSelectedStep(option.value);
                  }}
                  value={stepOptions.find((o) => o.value === selectedStep)}
                />
              </LabelAndInputGroup>
            </InputFields>
          )}
          {isInititalFitIssue && (
            <>
              <StyledAlertCard type="default" displayIcon header="">
                <AlertWrapper>
                  If the patient is on step 1 or 2, you need to{' '}
                  <StyledLinkButton onClick={jumpToQualityTicketModal}>
                    report an intial fit issue
                  </StyledLinkButton>
                  {enforceRefinementsModalRedirect &&
                    ' so that our quality team can investigate the problem prior to starting a refinement'}
                </AlertWrapper>
              </StyledAlertCard>
              {!enforceRefinementsModalRedirect ? (
                <>
                  <Question>Please describe the initial fit issue</Question>
                  <TextArea
                    placeholder="To the best of your ability, describe the fit issue. Where is the fit the worst? Is there anything else we should know?"
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setInitialFitIssueDescription(e.target.value);
                    }}
                    value={initialFitIssueDescription}
                  />
                </>
              ) : (
                <RowContainer>
                  <CheckboxInput
                    type={'checkbox'}
                    checked={ifiHasBeenReported}
                    onChange={(e) => {
                      setIfiHasBeenReported(e.target.checked);
                    }}
                  />
                  <CheckboxLabel>
                    The quality team has investigated my case and has advised me
                    to start a refinement
                  </CheckboxLabel>
                </RowContainer>
              )}
            </>
          )}
          <ReplacementCopy>
            If you&apos;re looking for a replacement aligner,{' '}
            <a href={formLink} rel="noopener noreferrer" target="_blank">
              use this form instead.
            </a>
          </ReplacementCopy>
        </Body>
      </StyledModal>
    </FullPageModal>
  );
};
