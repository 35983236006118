import React, { ReactNode, useState } from 'react';
import { colors, Avatar, Divider } from '@candidco/enamel';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

import { useAuthContext } from 'components/AuthProvider';

import LogOut from 'assets/ic_log-out.svg?react';
import Bullhorn from 'assets/sup_bullhorn.svg?react';
import HelpCircle from 'assets/ic_help-circle.svg?react';
import Eye from 'assets/ic_eye.svg?react';
import Arrow from 'assets/ic_chevron-right.svg?react';
import Settings from 'assets/ic_settings.svg?react';
import Dollar from 'assets/dollar.svg?react';
import Chat from 'assets/chat-icon.svg?react';
import { renderMobileDrawerHeader } from 'components/Header/utilities';
import { getBrandDomainSettings } from 'utils/brands';

import {
  MenuButton,
  StyledPaper,
  MenuHeader,
  MenuBody,
  UserName,
  UserEmail,
  UserMenuItemContainer,
  MenuItemLabel,
  MobileMenuContainer,
  DesktopMenuContainer,
  DesktopPopover,
  ClearChip,
  LoyaltyButton,
  PriceSection,
  LoyaltyLink,
  PriceTextSpan,
  PriceSpan,
  LoyaltyMultiPracticeLinkContent,
} from 'components/Header/UserMenu.css';

import { MobileDrawer } from 'components/Header/Header.css';
import { LoyaltyTierInfo } from 'constants/loyalty';
import { LoyaltyInfo } from 'components/Header/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

type UserMenuItemProps = {
  icon: ReactNode;
  label: ReactNode | string;
  handleClick: () => void;
};

type UserMenuConfigProps = {
  push: { (path: string, state?: History.PoorMansUnknown): void };
  handleLogOut: () => void;
  monitoringLabel: string;
  helpCenterUrl: string;
};

type MenuContentsProps = {
  doctorFullName?: string;
  doctorEmail?: string | null;
  loyaltyInfo: LoyaltyInfo;
  handleLogOut: () => void;
  push: {
    (path: string, state?: History.PoorMansUnknown): void;
  };
};

const userMenuConfig = ({
  push,
  handleLogOut,
  monitoringLabel,
  helpCenterUrl,
}: UserMenuConfigProps) => [
  {
    label: 'Preferences',
    icon: <Settings stroke={colors.black70} role="img" />,
    handleClick: () => push('/preferences'),
  },
  {
    label: `${monitoringLabel}`,
    icon: <Eye stroke={colors.black70} role="img" />,
    handleClick: () =>
      window.open(
        import.meta.env.VITE_REACT_APP_DENTAL_MONITORING_URL,
        '_blank'
      ),
  },
  {
    label: 'Marketing resources',
    icon: <Bullhorn stroke={colors.black70} role="img" />,
    handleClick: () => push('/marketing-resources'),
  },
  {
    label: 'Help center',
    icon: (
      <HelpCircle
        width={'20px'}
        height={'20px'}
        stroke={colors.black70}
        role="img"
      />
    ),
    handleClick: () => {
      window.open(helpCenterUrl);
    },
  },
  {
    label: 'Contact us',
    icon: (
      <Chat width={'20px'} height={'20px'} stroke={colors.black70} role="img" />
    ),
    handleClick: () => push('/contact-us'),
  },
  {
    label: 'Log out',
    icon: <LogOut stroke={colors.black70} role="img" />,
    handleClick: handleLogOut,
  },
];

const getAvatarComponent = (doctorName: string | undefined) => {
  return (
    <Avatar
      style={{
        width: 28,
        height: 28,
        backgroundColor: colors.blue50,
        paddingTop: '0.25rem',
        fontSize: '1rem',
      }}
    >
      {doctorName && doctorName[0]}
    </Avatar>
  );
};

const getMenuButton = (doctorName: string | undefined, mobile: boolean) => {
  if (mobile) {
    return getAvatarComponent(doctorName);
  }
  return (
    <MenuButton
      border="default"
      borderRadius={4}
      padding={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      {getAvatarComponent(doctorName)}

      {doctorName && <div style={{ marginLeft: '0.5rem' }}>{doctorName}</div>}
    </MenuButton>
  );
};

const UserMenuItem = (props: UserMenuItemProps) => {
  const { label, icon, handleClick } = props;

  return (
    <UserMenuItemContainer onClick={handleClick}>
      {icon}

      <MenuItemLabel>{label}</MenuItemLabel>
    </UserMenuItemContainer>
  );
};

//If name is null, we never got a practice to get a tier.
//If name is not null but price is, we're a DSO, and we shouldn't display price
//If name and price are not null, then we're not a DSO, so display price
const renderLoyaltyButton = (loyaltyInfo: LoyaltyInfo) => {
  if (!loyaltyInfo.enableLoyaltyProviderView) {
    return;
  }
  const tierName = loyaltyInfo?.name;
  const tierInfo = LoyaltyTierInfo[loyaltyInfo.sortOrder];
  return (
    <LoyaltyButton data-testid="user-menu-loyalty-button">
      <LoyaltyLink to="/loyalty">
        {!loyaltyInfo.name ? (
          <LoyaltyMultiPracticeLinkContent>
            Loyalty program
          </LoyaltyMultiPracticeLinkContent>
        ) : (
          <>
            <ClearChip
              variant="default"
              size="small"
              label={tierName}
              icon={<img alt="" src={tierInfo?.icon} />}
            />
            {loyaltyInfo.price && loyaltyInfo.displayPrice && (
              <PriceSection>
                <PriceSpan>
                  {(loyaltyInfo.price / 100).toLocaleString('en-us', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </PriceSpan>
                <PriceTextSpan>per case</PriceTextSpan>
              </PriceSection>
            )}
          </>
        )}
        <Arrow stroke={colors.black70} role="img" />
      </LoyaltyLink>
    </LoyaltyButton>
  );
};

const MenuContents = ({
  doctorFullName,
  doctorEmail,
  loyaltyInfo,
  handleLogOut,
  push,
}: MenuContentsProps) => {
  const avatarLetter = doctorFullName && doctorFullName[0];
  const { showLoyaltyInfo, monitoringLabel, helpCenterUrl } =
    getBrandDomainSettings();

  const { 'enable-provider-facing-invoices': enableProviderFacingInvoices } =
    useFlags();

  const menuItems = userMenuConfig({
    push,
    handleLogOut,
    monitoringLabel: monitoringLabel as string,
    helpCenterUrl: helpCenterUrl as string,
  });
  if (enableProviderFacingInvoices) {
    menuItems.splice(0, 0, {
      label: 'Payments',
      icon: <Dollar fill={colors.black70} role="img" />,
      handleClick: () => push('/invoice-summary'),
    });
  }

  return (
    <StyledPaper>
      <MenuHeader>
        <Avatar
          style={{
            width: 40,
            height: 40,
            backgroundColor: colors.blue50,
            paddingTop: '0.25rem',
            marginBottom: '1rem',
          }}
        >
          {avatarLetter}
        </Avatar>

        {doctorFullName && <UserName>{doctorFullName}</UserName>}
        <UserEmail>{doctorEmail}</UserEmail>
        {showLoyaltyInfo && renderLoyaltyButton(loyaltyInfo)}
      </MenuHeader>

      <Divider />

      <MenuBody>
        {menuItems.map((item) => (
          <UserMenuItem {...item} />
        ))}
      </MenuBody>
    </StyledPaper>
  );
};

type UserMenuProps = {
  doctorFullName?: string | undefined;
  doctorEmail?: string | undefined | null;
  loyaltyInfo: LoyaltyInfo;
  handleLogOut: () => void;
  push: { (path: string, state?: History.PoorMansUnknown): void };
  logoSubString?: string;
};

const DesktopUserMenu = ({
  doctorFullName,
  doctorEmail,
  loyaltyInfo,
  handleLogOut,
  push,
}: UserMenuProps) => {
  return (
    <DesktopMenuContainer>
      <DesktopPopover
        anchor={getMenuButton(doctorFullName, false)}
        on={'click'}
        direction={'bottom'}
      >
        <MenuContents
          doctorFullName={doctorFullName}
          doctorEmail={doctorEmail}
          loyaltyInfo={loyaltyInfo}
          handleLogOut={handleLogOut}
          push={push}
        />
      </DesktopPopover>
    </DesktopMenuContainer>
  );
};

const MobileUserMenu = ({
  doctorFullName,
  doctorEmail,
  loyaltyInfo,
  handleLogOut,
  push,
  logoSubString,
}: UserMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MobileMenuContainer onClick={onClick}>
      {getMenuButton(doctorFullName, true)}
      <MobileDrawer anchor="top" open={isOpen}>
        {renderMobileDrawerHeader(onClick, logoSubString, loyaltyInfo)}
        <Divider />
        <MenuContents
          doctorFullName={doctorFullName}
          doctorEmail={doctorEmail}
          loyaltyInfo={loyaltyInfo}
          handleLogOut={handleLogOut}
          push={push}
        />
      </MobileDrawer>
    </MobileMenuContainer>
  );
};

export type MenuProps = {
  logoSubString: string | undefined;
  loyaltyInfo: LoyaltyInfo;
};

export const UserMenu = ({ logoSubString, loyaltyInfo }: MenuProps) => {
  const { handleLogOut, userInfo } = useAuthContext();
  const doctorData = userInfo?.doctor;
  const { push } = useHistory();

  return (
    <>
      <MobileUserMenu
        handleLogOut={handleLogOut}
        doctorFullName={doctorData?.fullName}
        doctorEmail={userInfo?.email}
        push={push}
        logoSubString={logoSubString}
        loyaltyInfo={loyaltyInfo}
      />
      <DesktopUserMenu
        handleLogOut={handleLogOut}
        doctorFullName={doctorData?.fullName}
        doctorEmail={userInfo?.email}
        push={push}
        loyaltyInfo={loyaltyInfo}
      />
    </>
  );
};
