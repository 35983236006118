import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { RootState } from 'state/store';
import {
  ActivateOrderDocument,
  ActivateOrderMutation,
  ActivateOrderMutationVariables,
  BillingYaypayCustomerInvoiceStatusChoices,
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GetPracticePaymentStatusDocument,
  GetPracticePaymentStatusQuery,
  GetPracticePaymentStatusQueryVariables,
  GetProductVariantsDocument,
  GetProductVariantsQuery,
  GetProductVariantsQueryVariables,
} from 'generated/core/graphql';
import { client, coreClient } from 'gql/GraphQLProvider';
import {
  GetPracticesDocument,
  GetPracticesQuery,
  GetPracticesQueryVariables,
  OrganizationTypes,
} from 'generated/legacy/graphql';

type CommerceState = {
  productVariants: GetProductVariantsQuery['productVariants'];
  practices: GetPracticesQuery['practices'];
  currentOrder:
    | CreateOrderMutation['createOrder']
    | ActivateOrderMutation['activateOrder']
    | null;
  practicePaymentStatus?: GetPracticePaymentStatusQuery['practicePaymentStatus'];
};

const initialState: CommerceState = {
  productVariants: [],
  practices: [],
  currentOrder: null,
  practicePaymentStatus: undefined,
};

export const fetchProductVariants = createAsyncThunk(
  'commerce/fetchProductVariants',
  async () => {
    const { data } = await coreClient.mutate<
      GetProductVariantsQuery,
      GetProductVariantsQueryVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: GetProductVariantsDocument,
    });

    return data;
  }
);

export const fetchPracticePaymentStatus = createAsyncThunk(
  'commerce/fetchPracticePaymentStatus',
  async (practiceId: string) => {
    const { data } = await coreClient.mutate<
      GetPracticePaymentStatusQuery,
      GetPracticePaymentStatusQueryVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: GetPracticePaymentStatusDocument,
      variables: {
        practiceId: practiceId,
      },
    });

    return data;
  }
);

// This fetch action might belong in a different slice/domain. We will keep it here for now until
// a "org" domain is created
export const fetchPractices = createAsyncThunk(
  'commerce/fetchPractices',
  async (practiceType: OrganizationTypes) => {
    const { data } = await client.query<
      GetPracticesQuery,
      GetPracticesQueryVariables
    >({
      fetchPolicy: 'no-cache',
      query: GetPracticesDocument,
      variables: {
        organizationType: practiceType,
      },
    });

    return data;
  }
);

export const fetchPracticesByName = createAsyncThunk(
  'commerce/fetchPractices',
  async (practiceName: string) => {
    const { data } = await client.query<
      GetPracticesQuery,
      GetPracticesQueryVariables
    >({
      fetchPolicy: 'no-cache',
      query: GetPracticesDocument,
      variables: {
        name: practiceName,
      },
    });

    return data;
  }
);

type PracticeOrder = Required<
  Omit<CreateOrderMutationVariables, 'patientId' | 'caseRef'>
>;
type PatientOrder = Required<CreateOrderMutationVariables>;

export const createOrder = createAsyncThunk(
  'commerce/createOrder',
  async (input: PracticeOrder | PatientOrder) => {
    const { data } = await coreClient.mutate<
      CreateOrderMutation,
      CreateOrderMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: CreateOrderDocument,
      variables: input,
    });

    return data;
  }
);

export const activateOrder = createAsyncThunk(
  'commerce/activateOrder',
  async (input: ActivateOrderMutationVariables) => {
    const { data } = await coreClient.mutate<
      ActivateOrderMutation,
      ActivateOrderMutationVariables
    >({
      fetchPolicy: 'no-cache',
      mutation: ActivateOrderDocument,
      variables: input,
    });

    return data;
  }
);

/**
 * Commerce slice for managing all things commerce.
 * This includes fetching product variants, creating orders, and activating orders.
 *
 * To expand to include more commerce-related functionality around shipping and billing.
 *
 * System slice can be used for loading and error states via isPending, isRejected, isFulfilled, typePrefix, useIsLoading, useError.
 */
const commerceSlice = createSlice({
  name: 'commerce',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(fetchProductVariants.fulfilled, (state, action) => {
      state.productVariants = action.payload?.productVariants ?? [];
    });
    builder.addCase(fetchPractices.fulfilled, (state, action) => {
      state.practices = action.payload?.practices ?? [];
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.currentOrder = action.payload?.createOrder ?? null;
    });
    builder.addCase(activateOrder.fulfilled, (state, action) => {
      state.currentOrder = action.payload?.activateOrder ?? null;
    });
    builder.addCase(fetchPracticePaymentStatus.fulfilled, (state, action) => {
      state.practicePaymentStatus = action.payload?.practicePaymentStatus;
    });
  },
});

export const selectCurrentOrder = (state: RootState) =>
  state.commerce.currentOrder;
export const selectPractices = (state: RootState) => state.commerce.practices;

export const selectPracticePaymentStatus =
  (
    practiceId?: number,
    invoiceStatus?: BillingYaypayCustomerInvoiceStatusChoices
  ) =>
  (state: RootState) => {
    let practicePaymentStatus:
      | GetPracticePaymentStatusQuery['practicePaymentStatus']
      | undefined = undefined;

    if (practiceId == state.commerce.practicePaymentStatus?.practiceId) {
      practicePaymentStatus = state.commerce.practicePaymentStatus;
    }
    let invoices: GetPracticePaymentStatusQuery['practicePaymentStatus']['invoices'] =
      [];
    if (invoiceStatus && practicePaymentStatus?.invoices) {
      invoices = practicePaymentStatus.invoices?.filter(
        (invoice) => invoice.status.toString() === invoiceStatus.toString()
      );
    }

    return {
      ...practicePaymentStatus,
      invoices,
    };
  };

export const selectProductVariants = (
  state: RootState,
  hasCaseRequirement: boolean = false
) => {
  // Extract productVariants from the commerce slice of the state
  const productVariants = state.commerce.productVariants;
  // If hasCaseRequirement is true, filter productVariants to include only those with krakenFgs property
  // Otherwise, return all productVariants
  return hasCaseRequirement
    ? productVariants.filter((variant) => variant.krakenFgs)
    : productVariants;
};

export const selectCurrentOrderItems = createSelector(
  selectCurrentOrder,
  (order) =>
    order?.order?.orderItems.map((item) => ({
      caseRef: order?.order?.caseRef,
      createdAt: order?.order?.createdAt,
      orderId: order?.order?.id,
      practiceId: order?.order?.practiceId,
      shippingAddress: order?.order?.shippingAddress,
      status: order?.order?.status,
      updatedAt: order?.order?.updatedAt,
      orderItemRef: item.id,
      productVariantName: item.productVariant.name,
      productVariantSku: item.productVariant.sku,
      quantity: item.quantity,
    })) ?? []
);

export default commerceSlice.reducer;
