import { theme } from '@candidco/enamel';
import { useAccordionItemContext } from '@reach/accordion';
import {
  Button,
  HeaderRight,
  SubCategory,
  SubCategoryHeader,
  Checkbox,
  StyledChip,
  StyledAccordion,
  ArrowIcon,
  Panel,
  Title,
  Label,
} from 'components/QualityTicketModal/ProblemCategoryAccordion.css';

import { Problem } from 'components/QualityTicketModal/types';
import React, { useMemo } from 'react';
import { HRDividerLine } from 'styles/layout.css';

const Arrow = () => {
  const { isExpanded } = useAccordionItemContext();
  return <ArrowIcon $isExpanded={isExpanded} role="img" />;
};

type ProblemCategoryAccordionProps = {
  problem: Problem;
  checked: { [key: string]: boolean };
  onCheckboxUpdated: (key: string, value: boolean) => void;
};
const ProblemCategoryAccordion = ({
  problem,
  checked,
  onCheckboxUpdated,
}: ProblemCategoryAccordionProps) => {
  const numChecked = useMemo(() => {
    let total = 0;
    problem.subCategories.forEach((subCat) => {
      subCat.options.forEach((option) => {
        if (checked[option.sfValue]) {
          total++;
        }
      });
    });
    return total;
  }, [checked, problem]);
  return (
    <>
      <StyledAccordion>
        <Button>
          <Title>{problem.header}</Title>
          <HeaderRight>
            {numChecked > 0 && (
              <StyledChip
                size="small"
                label={numChecked}
                customColor={theme.colors.blue50}
              />
            )}
            <Arrow />
          </HeaderRight>
        </Button>

        <Panel>
          <HRDividerLine style={{ margin: 0 }} />
          {problem.subCategories.map((subCat) => {
            return (
              <SubCategory key={subCat.subHeader}>
                {subCat.subHeader && (
                  <SubCategoryHeader>{subCat.subHeader}</SubCategoryHeader>
                )}

                {subCat.options.map((option) => (
                  <Label key={option.sfValue}>
                    <Checkbox
                      type="checkbox"
                      checked={checked[option.sfValue] ?? false}
                      onChange={(e) => {
                        onCheckboxUpdated(option.sfValue, e.target.checked);
                      }}
                    />
                    {option.label}
                  </Label>
                ))}
              </SubCategory>
            );
          })}
        </Panel>
      </StyledAccordion>
    </>
  );
};

export default ProblemCategoryAccordion;
