import styled from 'styled-components/macro';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Chip,
  mediaQueries,
  Tooltip,
  theme,
} from '@candidco/enamel';
import { Link } from 'react-router-dom';
import ThumbsUpSVG from 'assets/thumbs-up-filled.svg?react';
import AddNewPatientIcon from 'assets/add-new-patient.svg?react';
import { withStyles } from '@material-ui/core/styles';
import { headerHeight } from 'styles/layout.css';

export const SectionContainer = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.space.md};
    border-radius: ${theme.radii.medium};
    text-align: center;
    width: 100%;
  `}
`;

export const AccordionContainer = styled(Grid)`
  width: 100%;
  margin-left: 10px;

  @media ${mediaQueries.mobile} {
    margin-left: 0px;
  }
`;

export const ItemsContainer = styled(Grid)`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.25rem;

  @media ${mediaQueries.mobile} {
    align-items: center;
    flex-direction: column;
  }
`;

export const OverviewContainer = styled.div`
  width: 350px;
  min-width: 280px;
  justify-content: flex-start;

  @media ${mediaQueries.mobile} {
    justify-content: center;
    width: 100%;
  }
`;

export const DashboardContainer = styled(Grid)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    display: block;
    padding: 0;
  }
`;

export const InvisibleAnchorTag = styled.a`
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
`;

export const StyledAccordion = styled(Accordion)`
  margin: 16px 0;
  border-radius: 4px;
  box-sizing: none;

  &.MuiAccordion-root:before {
    content: none;
  }
`;

export const MaterialIconRowContainer = styled.div`
  display: flex;
`;

export const MaterialIconContainer = styled.div`
  margin-right: 0.5rem;
`;

export const ActionLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledButton = styled(Button)`
  height: 3rem;
  width: 100%;
`;

export const TextAndIconContainer = styled.div`
  align-items: center
  gap: .15rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PatientIcon = styled(AddNewPatientIcon)`
  max-width: 1rem;
  position: relative;
  top: -0.1rem;
`;

export const ButtonTextContainer = styled.div`
  margin-left: 0.75rem;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  overflow-wrap: break-word;
`;

export const ActionStyledButton = styled(Button)`
  height: 2rem;
  padding: 0px 20px;
  font-size: 14px;
`;

export const StyledChip = styled(Chip)`
  margin-left: 10px;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 14px 16px;
`;

export const SnackbarAction = styled(Button)`
  border: none;
  padding: 0rem 1.25rem 0rem 1.25rem;
`;

export const StyledThumbsUpSVG = styled(ThumbsUpSVG)`
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.colors.green90};
`;

export const GreyTooltip = styled(Tooltip)`
  color: ${({ theme }) => theme.colors.black70};
`;

export const ToolTipWithArrow = withStyles({
  tooltip: {
    backgroundColor: theme.colors.black90,
  },
  arrow: {
    color: theme.colors.black90,
  },
})(Tooltip);

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const EmptyStateContainer = styled(Grid)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  padding: 10px;
  height: calc(100vh - ${headerHeight});
`;

export const PaddedItem = styled(Grid)`
  padding: 10px 0px;
`;

export const TextItem = styled(PaddedItem)`
  min-width: 300px;
  max-width: 400px;
  text-align: center;
`;

export const StyledNoPatientsImage = styled.img`
  height: 300px;
  width: 300px;
`;
