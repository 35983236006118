import {
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  TreatmentPlanningSoftware,
  TreatmentPlanStagingTypes,
} from 'generated/core/graphql';
import { coreClient } from 'gql/GraphQLProvider';

type CreationResource =
  AddTreatmentPlanStagingMutationVariables['creationResources'];

type CaseType = 'aligner' | 'retainer';

export type CreateTreatmentPlanStagingVariables = {
  caseRef: string;
  caseType: CaseType;
  patientId: number;
  software: TreatmentPlanningSoftware;
  lowerScanId: string;
  upperScanId: string;
};

const RESOURCES: Record<
  TreatmentPlanningSoftware,
  Record<CaseType, CreationResource>
> = {
  [TreatmentPlanningSoftware.Vision]: {
    aligner: {
      vision: {
        draftPlanMetadataAwsLocation:
          'materials/vision_aligner_template/planmetadata.json',
        draftReportAwsLocation: 'materials/vision_aligner_template/report.json',
        draftViewAwsLocation: 'materials/vision_aligner_template/viewer.zip',
        draftPlanAwsLocation: 'materials/vision_aligner_template/plan.zip',
      },
    },
    retainer: {
      vision: {
        draftPlanMetadataAwsLocation:
          'materials/vision_retainer_template/planmetadata.json',
        draftReportAwsLocation:
          'materials/vision_retainer_template/report.json',
        draftViewAwsLocation: 'materials/vision_retainer_template/viewer.zip',
        draftPlanAwsLocation: 'materials/vision_retainer_template/plan.zip',
      },
    },
  },
};

/**
 * Synthetic `addTreatmentPlanStaging` mutation used for creating default treatment plans.
 *
 * Abstracts away the `creationResources` and a few other things.
 */
export const addTemplatedTreatmentPlanStaging = async ({
  caseRef,
  caseType,
  patientId,
  software,
  lowerScanId,
  upperScanId,
}: CreateTreatmentPlanStagingVariables) =>
  coreClient.mutate<
    AddTreatmentPlanStagingMutation,
    AddTreatmentPlanStagingMutationVariables
  >({
    mutation: AddTreatmentPlanStagingDocument,
    variables: {
      patientId,
      caseRef,
      creationResources: RESOURCES[software][caseType],
      scanIds: {
        lowerScanId,
        upperScanId,
      },
      notes: '',
      treatmentPlanStagingType: TreatmentPlanStagingTypes.AlignerStaging,
      software,
    },
  });
