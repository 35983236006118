import React, { useEffect } from 'react';
import { type, AlertCard } from '@candidco/enamel';

import RefreshSvg from 'assets/ic_refresh-ccw.svg?react';
import CheckSvg from 'assets/check.svg?react';

import {
  AlertBody,
  StyledAlertContainer,
  StyledDiv,
} from 'pages/Patient/CaseCreator/DiagnosticMaterials/Xrays/RefinementXray.css';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetXraysByCaseRefQueryVariables,
  GetXraysByCaseRefDocument,
  GetXraysByCaseRefQuery,
} from 'generated/core/graphql';
import {
  getSubmissionsFromXrays,
  XrayFragment,
  selectCases,
  selectActiveCase,
} from 'pages/Patient/patientSlice';
import { useSelector } from 'react-redux';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';

type RefinementXraysProps = {
  alreadySynced: boolean;
  onSyncedClicked: (precedingXrays: XrayFragment[]) => void;
};

const RefinementXrays = ({
  alreadySynced,
  onSyncedClicked,
}: RefinementXraysProps) => {
  const activeCase = useSelector(selectActiveCase);
  const cases = useSelector(selectCases);

  const [getXrays, { loading: isLoading, data: coreXrayData }] = useGQLQuery<
    GetXraysByCaseRefQuery,
    GetXraysByCaseRefQueryVariables
  >(GetXraysByCaseRefDocument);

  const precedingCaseRef = activeCase?.precedingCaseRef;

  const precedingCase = cases?.find((c) => c?.caseRef === precedingCaseRef);

  const xrayData = coreXrayData?.getXrayMaterialsByCaseRef ?? [];
  const precedingXraySubmission = getSubmissionsFromXrays(xrayData)[0];

  const hasValidPrecedingCase =
    precedingCase?.caseRef &&
    precedingCase?.caseState?.providerFacing !==
      PROVIDER_FACING_STATUSES.CASE_CLOSED_INITIAL_FIT_ISSUE;

  useEffect(() => {
    if (!hasValidPrecedingCase) {
      return;
    }
    getXrays({
      caseRef: precedingCase.caseRef,
    });
  }, [hasValidPrecedingCase]);

  if (!hasValidPrecedingCase || !precedingXraySubmission || isLoading) {
    return null;
  }

  const handleSyncXrays = () => {
    const previousXrays = xrayData.filter((xray) =>
      xray.submissions.some(
        (submission) => submission.id === precedingXraySubmission.id
      )
    );
    onSyncedClicked(previousXrays);
  };

  const FirstTimeSyncComponent = () => {
    return (
      <StyledDiv>
        <RefreshSvg />
        <type.Link onClick={handleSyncXrays}>
          Sync the x-rays from the previous case, {precedingCase?.shortCaseRef}
        </type.Link>
      </StyledDiv>
    );
  };

  const HasSyncedComponent = () => {
    return (
      <StyledAlertContainer>
        <AlertCard type="default">
          <AlertBody>
            <CheckSvg />
            <div>
              Synced the x-rays from the previous case,
              {precedingCase?.shortCaseRef}
            </div>
            <type.Link onClick={handleSyncXrays}>Sync again</type.Link>
          </AlertBody>
        </AlertCard>
      </StyledAlertContainer>
    );
  };

  return alreadySynced ? <HasSyncedComponent /> : <FirstTimeSyncComponent />;
};

export default RefinementXrays;
