import styled from 'styled-components/macro';
import { SelectInput, TextInput, type, mediaQueries } from '@candidco/enamel';
import { Switch } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0;
  }
`;

export const RetainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 680px;
  width: 100%;
  padding: 0 10px;
`;

export const Title = styled.div`
  color: var(--black-transparent-87, rgba(0, 0, 0, 0.87));
  font-feature-settings:
    'clig' off,
    'liga' off;

  /* Large screens/HEADLINES/H5 */
  font-family: Modern Era;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
`;

export const Question = styled.div`
  color: ${({ theme }) => theme.colors.black90};
  font-feature-settings:
    'clig' off,
    'liga' off;

  /* Large screens/HEADLINES/H4 */
  font-family: Modern Era;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
  margin-bottom: 24px;
`;

export const CartItemContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 160px;
  max-height: 350px;
  padding: 24px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0.5rem;
    gap: 0.5rem;
  }
`;
export const Description = styled.div`
  color: ${({ theme }) => theme.colors.black70};

  /* Large screens/BODY/Small */
  font-family: Modern Era;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const StyledSelectInput = styled(SelectInput)<{
  $displayPrices?: boolean;
}>`
  width: ${(props) => (props.$displayPrices ? '16rem' : '4rem')};
`;

export const ItemBody = styled.div`
  max-width: 342px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyleToggle = styled(Switch)`
  & .MuiIconButton-root {
    color: ${({ theme }) => theme.colors.black50};
    opacity: 1;
    &:hover {
      background-color: transparent;
    }
  }
  & .MuiSwitch-thumb {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.text20};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    color: ${({ theme }) => theme.colors.blue50};

    &:hover {
      background-color: transparent;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.blue30};
  }
`;

export const Message = styled.span`
  display: none;
  @media ${mediaQueries.tabletAndAbove} {
    display: block;
    color: ${({ theme }) => theme.colors.black90};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const PlaceOrder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const QuestionHeading = styled(type.H4)`
  margin-bottom: 4px;
`;
export const QuestionBody = styled.p`
  color: ${({ theme }) => theme.colors.black70};
  margin-bottom: 16px;
`;

export const CartItemCostContainer = styled.div`
  display: flex;
  size: ${({ theme }) => theme.fontSizes.default};
  color: ${({ theme }) => theme.colors.black90};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const StrikethroughText = styled.span`
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.black70};
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

export const CouponTextInput = styled(TextInput)`
  padding-right: 0.5rem;
`;

export const CouponContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CouponError = styled.div`
  color: ${({ theme }) => theme.colors.red50};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
