import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPractice,
  fetchPracticeLoyaltyStatus,
  getSelectedPractice,
  getSelectedPracticeLoyaltyStatus,
  setContractRates,
} from 'pages/PracticeManagement/shared/slice';
import { Loading, NotificationContext } from '@candidco/enamel';
import {
  Container,
  Header,
  PracticeName,
  StyledBackButton,
  BackIcon,
  EditButton,
  StyledPencilIcon,
  Info,
  GridItem,
  Label,
  InfoText,
  TabContainer,
  Tab,
  TabLabelContainer,
  LoyaltyBadge,
} from 'pages/PracticeManagement/PracticeManager.css';
import UpdateLoyaltyStatusForm from 'pages/PracticeManagement/Loyalty/LoyaltyUpdateForm';
import LoyaltyEnrollmentForm from 'pages/PracticeManagement/Loyalty/LoyaltyEnrollmentForm';
import EditPracticeModal from 'components/EditPracticeModal/EditPracticeModal';
import { useIsLoading } from 'state/system';
import PricingTable from 'pages/PracticeManagement/PricingTable';

export type PracticeManagerParams = {
  practiceId: string;
};

const PracticeManager = () => {
  const dispatch = useDispatch();
  const { showNotification } = useContext(NotificationContext);
  const { practiceId } = useParams<PracticeManagerParams>();
  const [isEditingPractice, setIsEditingPractice] = React.useState(false);
  const [currentTabIndex, setCurrentTabIndex] = React.useState<number>(0);
  const selectedPractice = useSelector(getSelectedPractice);
  const selectedPracticeLoyaltyStatus = useSelector(
    getSelectedPracticeLoyaltyStatus
  );
  const isPracticeLoading = useIsLoading(fetchPractice.type);
  const isLoyaltyLoading = useIsLoading(fetchPracticeLoyaltyStatus.type);

  useEffect(() => {
    dispatch(fetchPractice({ id: practiceId }));
    dispatch(fetchPracticeLoyaltyStatus({ practiceId }));
    dispatch(setContractRates([]));
  }, [practiceId, dispatch]);

  const tabs = [
    {
      name: 'Loyalty program',
      Component: () => {
        if (selectedPracticeLoyaltyStatus) {
          return <UpdateLoyaltyStatusForm />;
        }
        return <LoyaltyEnrollmentForm />;
      },
    },
    {
      name: 'Pricing',
      Component: () => <PricingTable />,
    },
  ];

  if (isPracticeLoading || isLoyaltyLoading) {
    return (
      <Container>
        <Loading />;
      </Container>
    );
  }

  if (!selectedPractice) {
    return (
      <Container>
        <h1>Practice not found</h1>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <PracticeName>
          <StyledBackButton to={{ pathname: '/management/practice/' }}>
            <BackIcon />
          </StyledBackButton>
          <h4>{selectedPractice.name}</h4>
        </PracticeName>

        <EditButton
          buttonType="secondary"
          onClick={() => setIsEditingPractice(true)}
        >
          <StyledPencilIcon />
          Edit Info
        </EditButton>
        <EditPracticeModal
          practiceData={selectedPractice}
          isOpen={isEditingPractice}
          onClose={() => {
            setIsEditingPractice(false);
          }}
          onSuccess={() => {
            showNotification('Successfully updated practice info', 'success');
            setIsEditingPractice(false);
            dispatch(fetchPractice({ id: practiceId }));
            dispatch(fetchPracticeLoyaltyStatus({ practiceId }));
          }}
        />
      </Header>
      <Info container xs={12} spacing={10}>
        <GridItem item xs={'auto'}>
          <Label>Address</Label>
          <InfoText>
            {selectedPractice.address.addressLine1}
            {selectedPractice.address.addressLine2 ?? ''}
          </InfoText>
          <InfoText>
            {selectedPractice.address.city},{' '}
            {selectedPractice.address.stateCode} {selectedPractice.address.zip}
          </InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Practice ID</Label>
          <InfoText>{selectedPractice.id}</InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Account ID</Label>
          <InfoText>{selectedPractice.account.id}</InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Salesforce Child ID</Label>
          <InfoText>
            <a
              href={`https://candidco.lightning.force.com/lightning/r/Account/${
                selectedPractice.salesforceChildAccountId
              }/view`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedPractice.salesforceChildAccountId}
            </a>
          </InfoText>
        </GridItem>
        <GridItem item xs={'auto'}>
          <Label>Salesforce Parent ID</Label>
          <InfoText>
            <a
              href={`https://candidco.lightning.force.com/lightning/r/Account/${
                selectedPractice.account.salesforceParentAccountId
              }/view`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedPractice.account.salesforceParentAccountId}
            </a>
          </InfoText>
        </GridItem>
      </Info>
      <TabContainer>
        {tabs.map(({ name }, index) => (
          <Tab
            key={index}
            onClick={() => setCurrentTabIndex(index)}
            isActive={index === currentTabIndex}
          >
            <TabLabelContainer>
              {name}
              {name === 'Loyalty program' && (
                <LoyaltyBadge enrolled={!!selectedPracticeLoyaltyStatus}>
                  {selectedPracticeLoyaltyStatus ? 'Enrolled' : 'Not enrolled'}
                </LoyaltyBadge>
              )}
            </TabLabelContainer>
          </Tab>
        ))}
      </TabContainer>
      {tabs[currentTabIndex].Component()}
    </Container>
  );
};

export default PracticeManager;
