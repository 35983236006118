import { Grid } from '@candidco/enamel';
import styled from 'styled-components/macro';

export const Container = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.text40};
  padding: 0;
  margin: 0.5rem 0;
  align-items: center;
  border-radius: 8px;
  text-align: center;
  > div {
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.text70};
  }
`;

export const CheckBoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
`;

export const CheckBox = styled.div<{
  bottomRow: boolean;
}>`
  display: flex;
  flex-direction: ${({ bottomRow }) =>
    bottomRow ? 'column' : 'column-reverse'};
  text-align: center;
  gap: 0.25rem;
  > label {
    line-height: 150%;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 0.75rem;
  }
  > input,
  div,
  svg {
    appearance: checkbox;
    width: 1rem;
    height: 1rem;
  }
  padding: 0.1rem;
`;
