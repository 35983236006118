import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useGQLQuery } from 'hooks/useGQL';
import { Loading } from '@candidco/enamel';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  DefaultProtocolOptions,
  DoctorPreferencesInput,
} from 'generated/legacy/graphql';
import {
  FormQuestion,
  GetFormDocument,
  GetFormQuery,
  GetFormQueryVariables,
  IntakeTypes,
} from 'generated/core/graphql';

import { TreatmentProtocolLabels } from 'constants/Material';
import Question from 'components/FormikForms/Question';
import TreatmentProtocolsLink from 'components/TreatmentProtocolsLink';
import Tabs from 'components/Tabs';
import { DEFAULT_LINKS } from 'constants/brands';
import { Spacer } from 'styles/layout.css';
import { getDefaultQuestions, getQuestionKey } from 'utils/treatmentGoals';
import PreferenceSection from 'pages/AccountPreferencesPage/PreferenceSection';
import TreatmentFooter from 'pages/AccountPreferencesPage/Treatment/Footer';
import { useAuthContext } from 'components/AuthProvider';

const Questions = ({
  isLoading = false,
  questions,
  protocol,
}: {
  isLoading?: boolean;
  questions: FormQuestion[];
  protocol: DefaultProtocolOptions;
}) => {
  if (isLoading) {
    return <Loading isCentered />;
  }

  return (
    <Spacer isVertical>
      {questions?.map((question) => {
        return (
          <Question
            question={question}
            answerFieldName={getQuestionKey(question, protocol)}
            explanationFieldName={getQuestionKey(question, protocol)}
            listAnswerFieldName={getQuestionKey(question, protocol)}
            key={question.questionKey}
          />
        );
      })}
    </Spacer>
  );
};

const TreatmentPreferences = ({ id }: { id?: string }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultQuestions, setDefaultQuestions] = useState<{
    [key in DefaultProtocolOptions]: FormQuestion[];
  }>({
    [DefaultProtocolOptions.Candidairway]: [],
    [DefaultProtocolOptions.Candidrx]: [],
  });
  const { 'beta-treatment-goals': fetchBetaForms } = useFlags();
  const [getFormQuestions] = useGQLQuery<GetFormQuery, GetFormQueryVariables>(
    GetFormDocument
  );
  const { setFieldValue, values } = useFormikContext<DoctorPreferencesInput>();
  const setDefaultProtocol = (protocol: DefaultProtocolOptions | null) => {
    setFieldValue('defaultProtocol', protocol);
  };
  const { userInfo } = useAuthContext();
  const treatmentGoalsProtocols =
    userInfo?.brandInfo?.configs?.zendesk?.treatmentGoalsProtocols;

  useEffect(() => {
    const fetchQuestions = async () => {
      const [rxQuestions, airwayQuestions] = await Promise.all([
        getFormQuestions({
          materialType: IntakeTypes.CandidRxTreatmentGoals,
          beta: fetchBetaForms,
        }),
        getFormQuestions({
          materialType: IntakeTypes.CandidAirwayTreatmentGoals,
          beta: fetchBetaForms,
        }),
      ]);

      setIsLoading(false);

      const filteredRxQuestions = getDefaultQuestions(
        rxQuestions?.getForm?.formSchema
      );
      const filteredAirwayQuestions = getDefaultQuestions(
        airwayQuestions?.getForm?.formSchema
      );
      setDefaultQuestions({
        [DefaultProtocolOptions.Candidrx]: filteredRxQuestions,
        [DefaultProtocolOptions.Candidairway]: filteredAirwayQuestions,
      });
    };

    fetchQuestions();
  }, [fetchBetaForms]);

  const tabs = [
    {
      id: DefaultProtocolOptions.Candidrx,
      name: TreatmentProtocolLabels.StandardRx,
      Component: () => (
        <Questions
          isLoading={isLoading}
          questions={defaultQuestions[DefaultProtocolOptions.Candidrx]}
          protocol={DefaultProtocolOptions.Candidrx}
        />
      ),
    },
    {
      id: DefaultProtocolOptions.Candidairway,
      name: TreatmentProtocolLabels.Airway,
      Component: () => (
        <Questions
          isLoading={isLoading}
          questions={defaultQuestions[DefaultProtocolOptions.Candidairway]}
          protocol={DefaultProtocolOptions.Candidairway}
        />
      ),
    },
  ];

  return (
    <PreferenceSection
      title="Treatment preferences"
      id={id}
      description={
        <>
          Select your default answers to questions for each design protocol.
          Find more information about our two design protocols{' '}
          <TreatmentProtocolsLink
            copy="here"
            link={
              treatmentGoalsProtocols || DEFAULT_LINKS.TREATMENT_GOALS_PROTOCOLS
            }
          />
          .
        </>
      }
    >
      <Tabs
        tabs={tabs}
        renderFooter={(activeTabId) => {
          if (isLoading) {
            return null;
          }

          const activeTab = tabs.find((tab) => tab.id === activeTabId);
          const checked = values.defaultProtocol === activeTab!.id;

          return (
            <TreatmentFooter
              checked={checked}
              protocolName={activeTab!.name}
              onToggle={(e) => {
                const checked = e.target.checked;

                if (checked) {
                  setDefaultProtocol(activeTab!.id);
                } else {
                  setDefaultProtocol(null);
                }
              }}
            />
          );
        }}
      />
    </PreferenceSection>
  );
};

export default TreatmentPreferences;
