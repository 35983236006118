import {
  GetCurrentPracticeLoyaltyProgramQuery,
  UpdateCurrentPracticeLoyaltyStatusDocument,
  UpdateCurrentPracticeLoyaltyStatusMutation,
  UpdateCurrentPracticeLoyaltyStatusMutationVariables,
} from 'generated/core/graphql';
import { useGQLMutation } from 'hooks/useGQL';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContext, TextInput } from '@candidco/enamel';
import {
  StyledDatePicker,
  StyledButton,
  Flex,
  Container,
  StyledSmall,
  LoyaltySelect,
} from 'pages/PracticeManagement/Loyalty/LoyaltyUpdateForm/LoyaltyUpdateForm.css';
import {
  getLoyaltyPrograms,
  getSelectedPractice,
  getSelectedPracticeLoyaltyStatus,
  setSelectedPracticeLoyaltyStatus,
} from 'pages/PracticeManagement/shared/slice';
import moment from 'moment';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';

type LoyaltyProgramTier = NonNullable<
  GetCurrentPracticeLoyaltyProgramQuery['getCurrentPracticeLoyaltyProgram']['loyaltyProgramTier']
>;

type LoyaltySelectOption = {
  value: LoyaltyValue;
  label: string;
} | null;

type LoyaltyValue = {
  id?: string;
  name: string;
  loyaltyProgramTiers?: LoyaltyProgramTier[];
};

const loyaltyTierToSelectOption = (
  loyaltyProgram: LoyaltyProgramTier
): LoyaltySelectOption => ({
  label: loyaltyProgram.name,
  value: loyaltyProgram,
});

const UpdateLoyaltyStatusForm = () => {
  const dispatch = useDispatch();
  const { showNotification } = useContext(NotificationContext);
  const { checkHasAccess } = useContext(AuthContext);
  const canEdit = checkHasAccess(ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN);
  const selectedPractice = useSelector(getSelectedPractice);
  const selectedPLS = useSelector(getSelectedPracticeLoyaltyStatus);
  const loyaltyProgramsResult = useSelector(getLoyaltyPrograms);
  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] =
    useState<LoyaltySelectOption>();
  const [selectedLoyaltyProgramTier, setSelectedLoyaltyProgramTier] =
    useState<LoyaltySelectOption>();
  const [selectedProgramStart, setProgramStart] = useState<string>();
  const [selectedProgramEnd, setProgramEnd] = useState<string>();
  const [note, setNote] = useState<string>();

  useEffect(() => {
    const program = selectedPractice?.enrolledLoyaltyProgram;
    if (program) {
      setSelectedLoyaltyProgram({
        label: program.loyaltyProgram.name,
        value: program.loyaltyProgram,
      });
      setSelectedLoyaltyProgramTier({
        label: program.loyaltyProgram.currentTier.name,
        value: program.loyaltyProgram.currentTier,
      });
      setProgramStart(program.programStart);
      setProgramEnd(program.programEnd);
    }
  }, [selectedPLS]);

  const [submitPracticeLoyaltyStatusUpdate, { loading }] = useGQLMutation<
    UpdateCurrentPracticeLoyaltyStatusMutation,
    UpdateCurrentPracticeLoyaltyStatusMutationVariables
  >(UpdateCurrentPracticeLoyaltyStatusDocument);

  const mutationVariables = useMemo(() => {
    // monitoring if the fields have been touched
    // if they have, we will include them in the mutation
    return {
      newTierId: {
        value: selectedLoyaltyProgramTier?.value?.id,
        touched:
          selectedLoyaltyProgramTier?.value.id !==
          selectedPLS?.loyaltyProgram.currentTier.id,
      },
      newProgramStart: {
        value: selectedProgramStart,
        touched:
          moment(selectedProgramStart).date() !==
          moment(selectedPLS?.programStart).date(),
      },
      newProgramEnd: {
        value: selectedProgramEnd,
        touched:
          moment(selectedProgramEnd).date() !==
          moment(selectedPLS?.programEnd).date(),
      },
      note: {
        value: note,
        touched: !!note,
      },
    };
  }, [
    selectedLoyaltyProgram,
    selectedLoyaltyProgramTier,
    selectedProgramStart,
    selectedProgramEnd,
    note,
  ]);

  const loyaltyProgramOptions = useMemo(
    () => loyaltyProgramsResult.map(loyaltyTierToSelectOption),
    [loyaltyProgramsResult]
  );
  const selectedLoyaltyProgramTiers = useMemo(
    () =>
      // If the tier has been selected, use the tiers from the selected loyalty program
      // Otherwise, use the tiers from the enrolled loyalty program
      mutationVariables.newTierId.touched
        ? selectedLoyaltyProgram?.value?.loyaltyProgramTiers ?? []
        : selectedPractice?.enrolledLoyaltyProgram?.loyaltyProgram
            .tiersInProgram ?? [],
    [selectedLoyaltyProgram, mutationVariables.newTierId.touched]
  );
  const loyaltyProgramTierOptions = useMemo(
    () => selectedLoyaltyProgramTiers.map(loyaltyTierToSelectOption) ?? [],
    [selectedLoyaltyProgramTiers]
  );

  const handleSubmit = async () => {
    const practiceId = parseInt(selectedPractice?.id ?? '');
    if (!practiceId) {
      return;
    }
    const variables: UpdateCurrentPracticeLoyaltyStatusMutationVariables = {
      practiceId,
    };

    Object.keys(mutationVariables).forEach((key) => {
      // add the value to the mutation input if the field has been touched
      type Key = keyof typeof mutationVariables;
      const { value, touched } = mutationVariables[key as Key];
      if (touched) {
        variables[key as Key] = value;
      }
    });

    const result = await submitPracticeLoyaltyStatusUpdate(variables);
    if (result?.updateCurrentPracticeLoyaltyStatus?.practiceLoyaltyStatus) {
      showNotification('Practice loyalty adjusted successfully', 'success');
      dispatch(
        setSelectedPracticeLoyaltyStatus({
          getCurrentPracticeLoyaltyProgram:
            result.updateCurrentPracticeLoyaltyStatus.practiceLoyaltyStatus,
        })
      );
    }
    setNote('');
  };

  const isSubmitDisabled =
    !Object.values(mutationVariables).some((field) => field.touched) ||
    !selectedLoyaltyProgramTier ||
    !canEdit;

  return (
    <Container>
      <h3>Edit loyalty settings</h3>
      <Flex>
        <StyledSmall>Select Loyalty Program</StyledSmall>
        <LoyaltySelect
          options={loyaltyProgramOptions}
          onChange={(selection: LoyaltySelectOption) => {
            setSelectedLoyaltyProgram(selection);
            setSelectedLoyaltyProgramTier(null);
          }}
          value={selectedLoyaltyProgram}
          isDisabled={!canEdit}
        />
        <StyledSmall>Select tier</StyledSmall>
        <LoyaltySelect
          options={loyaltyProgramTierOptions}
          onChange={(selection: LoyaltySelectOption) =>
            setSelectedLoyaltyProgramTier(selection)
          }
          value={selectedLoyaltyProgramTier}
          isDisabled={!canEdit}
        />
        <StyledSmall>Program start</StyledSmall>
        <StyledDatePicker
          value={selectedProgramStart}
          onChange={(newValue) =>
            setProgramStart(newValue?.toISOString() ?? '')
          }
          disabled={!canEdit}
        />
        <StyledSmall>Program end</StyledSmall>
        <StyledDatePicker
          value={selectedProgramEnd}
          onChange={(newValue) => {
            setProgramEnd(newValue?.toISOString() ?? '');
          }}
          disabled={!canEdit}
        />
      </Flex>
      <TextInput
        value={note ?? ''}
        label="Save a reason for this change"
        onChange={(e) => {
          setNote(e.currentTarget.value);
        }}
        disabled={!canEdit}
      />
      <StyledButton
        onClick={handleSubmit}
        buttonType="secondary"
        disabled={isSubmitDisabled}
        isLoading={loading}
      >
        Save changes
      </StyledButton>
    </Container>
  );
};

export default UpdateLoyaltyStatusForm;
