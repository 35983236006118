import React, { useEffect, useState, FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import moment from 'moment';
import { colors, Button, mediaQueries, type, Table } from '@candidco/enamel';
import { Sort, sortByCreated } from 'utils/prism';
import DocumentTitle from 'components/DocumentTitle';
import { ErrorText, Input as FormInput } from 'styles/inputs.css';
import {
  PageHeader,
  PageHeaderContent,
  PageHeading,
  PageSection,
} from 'styles/layout.css';
import { useGQLQuery } from 'hooks/useGQL';
import {
  GetTreatmentPlanStagingsByPatientIdDocument,
  GetTreatmentPlanStagingsByPatientIdQuery,
  GetTreatmentPlanStagingsByPatientIdQueryVariables,
  TreatmentPlanStagingStates,
} from 'generated/core/graphql';
import {
  GetCasesForSearchQuery as useGetCoreCasesQuery,
  GetCasesForSearchDocument,
  GetCasesForSearchQueryVariables,
} from 'generated/core/graphql';
import { getCaseTypeLabel } from 'utils/case';

const DATE_FORMAT = 'll'; // "MMM. d, YYYY"
const SmallHeading = styled(type.H4)`
  margin-bottom: 1rem;
`;

const SearchForm = styled.form`
  display: flex;
  gap: 1rem;
`;

const Input = styled(FormInput)`
  flex: 1;
  width: 50%;
  max-width: 12rem;
`;

const SubmitButton = styled(Button)`
  @media ${mediaQueries.mobile} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

const SearchResults = styled.section`
  padding-top: 5rem;
`;

const ActiveCell = styled.div<{
  isActive: boolean;
}>`
  color: ${({ isActive }) => (isActive ? colors.green90 : colors.black90)};
`;

type CaseSearchPageProps = {
  patientId?: string;
  onSelectCase: (caseItem: any) => void;
  pageTitle: string;
  searchCta?: string;
};

const CaseSearchPage: FC<CaseSearchPageProps> = ({
  patientId = '',
  onSelectCase,
  pageTitle,
  searchCta = 'Search',
}) => {
  const [searchId, setSearchId] = useState(patientId);
  const [
    getCoreCases,
    { loading: coreCaseLoading, data: coreCases, errors: error },
  ] = useGQLQuery<useGetCoreCasesQuery, GetCasesForSearchQueryVariables>(
    GetCasesForSearchDocument
  );
  const [
    getTreatmentPlanStagingsByPatientId,
    { loading: isLoadingTreatmentPlanStagings, data: treatmentPlanStagings },
  ] = useGQLQuery<
    GetTreatmentPlanStagingsByPatientIdQuery,
    GetTreatmentPlanStagingsByPatientIdQueryVariables
  >(GetTreatmentPlanStagingsByPatientIdDocument, true);
  const { push } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (patientId) {
      getCoreCases({
        patientIds: [patientId].map(Number) ?? [],
      });
      getTreatmentPlanStagingsByPatientId({ patientId: parseInt(patientId) });
    }
  }, [patientId]);

  const pathBase = pathname.split('/')[1];
  // const customer = customerWithCasesData?.customer;
  const isQc: boolean = useMemo(
    () => pathname.includes('tp-quality-control'),
    [pathname]
  );

  const cases = useMemo(() => {
    if (isQc && treatmentPlanStagings) {
      // Filter out cases without treatment plans in "QC_REVIEW" or "QC_REJECTED" states`
      const hasTpStagingsInQCState =
        treatmentPlanStagings.getTreatmentPlanStagingMaterials?.filter(
          (tpStaging) =>
            tpStaging?.state === TreatmentPlanStagingStates.QcReview ||
            tpStaging?.state === TreatmentPlanStagingStates.QcRejected
        );
      const tpStagingCaseRefs = hasTpStagingsInQCState?.map(
        (tpStaging) => tpStaging?.caseRef
      );
      return coreCases?.getCases
        ?.filter((c) => {
          return tpStagingCaseRefs?.includes(c?.caseRef);
        })
        .sort(sortByCreated(Sort.Desc));
    }

    return coreCases?.getCases?.sort(sortByCreated(Sort.Desc)) ?? [];
  }, [coreCases?.getCases, isQc, treatmentPlanStagings]);

  const onSubmitSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isOrthoReview = pageTitle === 'Ortho review';
    if (searchId) {
      push(`/${pathBase}/${searchId}${isOrthoReview ? '/cases' : ''}`);
    }
  };

  return (
    <DocumentTitle title={pageTitle}>
      <>
        <PageHeader>
          <PageHeaderContent>
            <PageHeading>{pageTitle}</PageHeading>
          </PageHeaderContent>
        </PageHeader>
        <PageSection>
          <SmallHeading>Search by patient ID</SmallHeading>
          <SearchForm>
            <Input
              name="patientID"
              placeholder="Patient ID"
              required
              type="number"
              onChange={(e) => setSearchId(e.target.value)}
              value={searchId}
            />
            <SubmitButton
              buttonType="secondary"
              disabled={coreCaseLoading || isLoadingTreatmentPlanStagings}
              isLoading={coreCaseLoading}
              isShort
              onClick={(e) => {
                onSubmitSearch(e);
              }}
            >
              {searchCta}
            </SubmitButton>
          </SearchForm>
          {error && <ErrorText>{error.message}</ErrorText>}
          {cases && (
            <SearchResults>
              <Table
                columns={[
                  {
                    name: 'isActive',
                    label: 'Active',
                    options: {
                      customBodyRender: (value: boolean) => {
                        return value ? (
                          <ActiveCell isActive>Active</ActiveCell>
                        ) : (
                          <ActiveCell isActive={false}>Inactive</ActiveCell>
                        );
                      },
                    },
                  },
                  {
                    name: 'caseRef',
                    label: 'Case Ref',
                  },
                  {
                    name: 'patientId',
                    label: 'Patient ID',
                  },
                  {
                    name: 'caseType',
                    label: 'Case type',
                    options: {
                      customBodyRender: (value: any) => {
                        return getCaseTypeLabel(value?.label ?? '');
                      },
                    },
                  },
                  {
                    name: 'source',
                    label: 'Source',
                  },
                  {
                    name: 'caseState',
                    label: 'Latest case status',
                    options: {
                      customBodyRender: (value: any) => {
                        return value?.internal;
                      },
                    },
                  },
                  {
                    name: 'caseState',
                    label: 'Provider facing state',
                    options: {
                      customBodyRender: (value: any) => {
                        return value?.providerFacing;
                      },
                    },
                  },
                  {
                    name: 'createdAt',
                    label: 'Dates',
                    options: {
                      customBodyRender: (value: string) => {
                        return value ? moment(value).format(DATE_FORMAT) : null;
                      },
                    },
                  },
                  {
                    name: 'caseRef',
                    label: 'Select',
                    options: {
                      customBodyRender: (value: string) => {
                        return (
                          <Button
                            buttonType="secondary-outline"
                            onClick={() => {
                              onSelectCase(
                                coreCases?.getCases?.find(
                                  (c) => c?.caseRef === value
                                )
                              );
                            }}
                            isShort
                          >
                            Select
                          </Button>
                        );
                      },
                    },
                  },
                ]}
                data={cases}
                options={{
                  selectableRows: 'none',
                  fixedHeader: false,
                  pagination: false,
                  sortOrder: {
                    name: 'lastUpdated',
                    direction: 'asc',
                  },
                  responsive: 'vertical',
                }}
              />
            </SearchResults>
          )}
        </PageSection>
      </>
    </DocumentTitle>
  );
};

CaseSearchPage.propTypes = {
  onSelectCase: PropTypes.func.isRequired,
  pageTitle: PropTypes.string.isRequired,
  searchCta: PropTypes.string,
};

export default CaseSearchPage;
