import React, { useContext, useMemo } from 'react';
import { Column } from 'react-virtualized';
import { Skeleton } from '@candidco/enamel';

import VirtualizedSearchTable, {
  monitoringLinkRenderer,
  customerCellRenderer,
  privateCellRenderer,
} from 'components/SearchTablePage/table';
import { AuthContext } from 'components/AuthProvider';
import { toSentenceCase } from 'utils/string';
import { getBrandDomainSettings } from 'utils/brands';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCaseTypeLabel } from 'utils/case';

export const OrthoPrismSearchTable = () => {
  const { 'white-labeling-brand-support': whiteLabeling } = useFlags();
  const { monitoringLabel } = getBrandDomainSettings();
  const { isProDoctor } = useContext(AuthContext);
  const customerCellBaseUrl = isProDoctor ? 'patient' : 'ortho-prism';

  const columns = useMemo(
    () => [
      <Column
        label="ID"
        dataKey="id"
        key="id"
        width={100}
        cellRenderer={({ cellData }) =>
          customerCellRenderer(cellData, customerCellBaseUrl)
        }
      />,
      <Column
        label="First name"
        dataKey="firstName"
        key="firstName"
        headerStyle={{ textTransform: 'none' }}
        width={125}
        flexGrow={1}
        cellRenderer={({ cellData }) => privateCellRenderer(cellData)}
      />,
      <Column
        label="Last name"
        dataKey="lastName"
        key="lastName"
        width={125}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label="Case type"
        dataKey="caseType"
        key="caseType"
        width={250}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? (
            getCaseTypeLabel(cellData ?? '')
          ) : (
            <Skeleton width={100} />
          )
        }
      />,
      <Column
        label="Latest case status"
        dataKey="internal"
        key="internal"
        headerStyle={{ textTransform: 'none' }}
        width={250}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          cellData !== '-' ? toSentenceCase(cellData) : <Skeleton width={100} />
        }
      />,
      <Column
        label="Treating Provider"
        dataKey="treatingProvider"
        key="treatingProvider"
        width={150}
        flexGrow={1}
        headerStyle={{ textTransform: 'none' }}
      />,
      <Column
        label={monitoringLabel}
        dataKey="email"
        key="email"
        width={150}
        flexGrow={1}
        cellRenderer={({ cellData }) =>
          monitoringLinkRenderer(cellData, whiteLabeling)
        }
      />,
    ],
    []
  );

  return (
    <VirtualizedSearchTable
      columns={columns}
      filterType="internal_status"
      title="Patients"
    />
  );
};
