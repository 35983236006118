import React, { useMemo, useEffect } from 'react';
import { Form, FormikValues, useFormikContext } from 'formik';
import { Divider } from '@candidco/enamel';

import {
  FormSection,
  FormQuestion,
  QuestionTypes,
} from 'generated/core/graphql';
import { FormikTextAreaWrapper } from 'components/FormikForms';
import {
  optionalValidator,
  requiredValidator,
  YesNoEnum,
  getListAnswerFieldName,
  getAnswerFieldName,
  getExplanationFieldName,
} from 'components/FormikForms/utils';
import { Spacer, IndentedSpacer } from 'styles/layout.css';
import { StringMap } from 'utils/types';
import Question from 'components/FormikForms/Question';
import DefaultAnswersSectionFooter from 'components/FormikForms/DefaultAnswersSectionFooter';
import { DentalNotationOptions } from 'generated/legacy/graphql';

type FormProps = {
  sections: FormSection[];
  skipValidation: boolean;
  stepTitle: string;
  onValuesChanged: (values: FormikValues) => void;
  disabled?: boolean;
  defaultValues?: FormikValues;
  dentalNotation?: DentalNotationOptions;
};

type SectionProps = {
  section: FormSection;
  skipValidation: boolean;
  disabled?: boolean;
  defaultValues?: FormikValues;
  dentalNotation?: DentalNotationOptions;
};

type QuestionProps = {
  formQuestion: FormQuestion;
  index: number;
  sectionLabel: string;
  skipValidation: boolean;
  disabled?: boolean;
  defaultValues?: FormikValues;
  dentalNotation?: DentalNotationOptions;
};

const QuestionForm = (FormQuestionProps: QuestionProps) => {
  const {
    sectionLabel,
    formQuestion,
    skipValidation,
    disabled,
    defaultValues,
    dentalNotation,
  } = FormQuestionProps;
  const {
    questionType,
    questionKey,
    explanationLabel,
    needExplanationFor,
    isFollowUpQuestion,
    isRequired,
  } = formQuestion;
  const { values } = useFormikContext<StringMap>();

  const answerFieldName = getAnswerFieldName(sectionLabel, questionKey!);
  const explanationFieldName = getExplanationFieldName(
    sectionLabel,
    questionKey!
  );

  const listAnswerFieldName = getListAnswerFieldName(
    sectionLabel,
    questionKey!
  );
  const validator =
    (isRequired ?? true) && !skipValidation
      ? requiredValidator
      : optionalValidator;

  const showExplanation = useMemo(() => {
    if (questionType === QuestionTypes.Boolean) {
      return needExplanationFor && values[answerFieldName] === YesNoEnum.yes;
    }
    return (
      questionType !== QuestionTypes.Date &&
      questionType !== QuestionTypes.ToothChart &&
      questionType !== QuestionTypes.Choice
    );
  }, [FormQuestionProps, values]);

  const Wrapper = isFollowUpQuestion ? IndentedSpacer : Spacer;

  return (
    <Wrapper isVertical>
      <Question
        question={formQuestion}
        validator={validator}
        answerFieldName={answerFieldName}
        explanationFieldName={explanationFieldName}
        listAnswerFieldName={listAnswerFieldName}
        defaultValues={defaultValues}
        dentalNotation={dentalNotation}
        disabled={disabled}
      />
      {showExplanation && (
        <FormikTextAreaWrapper
          name={explanationFieldName}
          validate={validator}
          disabled={disabled}
          type="text"
          label={explanationLabel || ''}
        />
      )}
    </Wrapper>
  );
};

const SectionForm = ({
  section: Section,
  skipValidation,
  disabled,
  defaultValues,
  dentalNotation,
}: SectionProps) => {
  const { label, questions } = Section;
  return (
    <Spacer spacing="2rem" isVertical>
      {questions?.map((question, i) => (
        <QuestionForm
          key={i}
          sectionLabel={label}
          index={i}
          formQuestion={question}
          skipValidation={skipValidation}
          disabled={disabled}
          defaultValues={defaultValues}
          dentalNotation={dentalNotation}
        />
      ))}
    </Spacer>
  );
};

/**
 * This fixes a bug/feature where the red error text wasn't showing on our inputs
 * after we press the submit button.
 * For our formik wrappers, an error is shown if the field has an error AND was touched.
 * The issue is the field wasn't being touched. Formik only touches initialValues automatically.
 * We can add this patch to a form to touch everything after submit
 *
 * https://github.com/formium/formik/issues/445#issuecomment-677592750
 */
const FormikPatchTouched = () => {
  const { errors, setFieldTouched, isSubmitting, isValidating } =
    useFormikContext<StringMap>();
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      for (const path of Object.keys(errors)) {
        setFieldTouched(path, true, false);
      }
    }
  }, [errors, isSubmitting, isValidating, setFieldTouched]);
  return null;
};

export const FormQuestions = ({
  sections,
  skipValidation,
  onValuesChanged,
  disabled,
  defaultValues,
  dentalNotation,
}: FormProps) => {
  const { values } = useFormikContext<StringMap>();

  useEffect(() => {
    onValuesChanged(values);
  }, [values]);
  return (
    <Form data-testid={'form'}>
      <FormikPatchTouched />
      <Spacer spacing="2rem" isVertical>
        {sections &&
          sections.map((section, i) => {
            const showDefaultableFooter = section.questions?.some(
              (question) => question?.hasDefaultPreferenceOption
            );

            return (
              <>
                <SectionForm
                  key={i}
                  section={section}
                  skipValidation={skipValidation}
                  disabled={disabled}
                  defaultValues={defaultValues}
                  dentalNotation={dentalNotation}
                />
                {showDefaultableFooter && <DefaultAnswersSectionFooter />}
                {i !== sections.length - 1 && <Divider />}
              </>
            );
          })}
      </Spacer>
    </Form>
  );
};
