import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import ReactSelect from 'react-select';
import { Button, NotificationContext } from '@candidco/enamel';
import {
  fetchLoyaltyPrograms,
  getLoyaltyPrograms,
  setSelectedPracticeLoyaltyStatus,
} from 'pages/PracticeManagement/shared/slice';
import { useParams } from 'react-router-dom';
import { useGQLMutation } from 'hooks/useGQL';
import {
  EnrollPracticeToLoyaltyProgramDocument,
  EnrollPracticeToLoyaltyProgramMutation,
  EnrollPracticeToLoyaltyProgramMutationVariables,
} from 'generated/core/graphql';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';

type LoyaltyValue = {
  id: string;
  name: string;
  loyaltyProgramTiers?: {
    id: string;
    name: string;
  }[];
};

type LoyaltySelectOption = {
  label: string;
  value: LoyaltyValue;
};

const Flex = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction ?? 'column'};
  gap: 0.5rem;
  > div {
    gap: 0rem;
  }
`;

const Container = styled(Flex)`
  width: 50vw;
  max-width: 450px;
  margin: 0;
`;

const StyledButton = styled(Button)`
  width: max-content;
  padding: 0.75rem 1.5rem;
`;

const Label = styled.p`
  margin: 0;
`;

const LoyaltySelect = styled(ReactSelect)<{
  hasError?: boolean;
  width?: number;
}>`
  min-width: 200px;
  height: 2.5rem;
  margin-bottom: 1rem;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '200px')};
`;

const convertToOptions = (
  loyaltyProgram: LoyaltyValue
): LoyaltySelectOption => ({
  label: loyaltyProgram.name,
  value: loyaltyProgram,
});

const LoyaltyEnrollmentForm = () => {
  const dispatch = useDispatch();
  const { checkHasAccess } = useContext(AuthContext);
  const canEdit = checkHasAccess(ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN);
  const { showNotification } = useContext(NotificationContext);
  const { practiceId } = useParams<{ practiceId: string }>();
  const loyaltyProgramsResult = useSelector(getLoyaltyPrograms);
  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] =
    useState<LoyaltySelectOption>();
  const [selectedLoyaltyProgramTier, setSelectedLoyaltyProgramTier] =
    useState<LoyaltySelectOption>();
  const selectedLoyaltyProgramTiers =
    selectedLoyaltyProgram?.value.loyaltyProgramTiers ?? [];
  const loyaltyProgramOptions = useMemo(
    () => loyaltyProgramsResult.map(convertToOptions),
    [loyaltyProgramsResult]
  );
  const loyaltyProgramTierOptions = useMemo(
    () => selectedLoyaltyProgramTiers.map(convertToOptions) ?? [],
    [selectedLoyaltyProgramTiers]
  );

  const [enrollPracticeToLoyaltyProgram, { loading }] = useGQLMutation<
    EnrollPracticeToLoyaltyProgramMutation,
    EnrollPracticeToLoyaltyProgramMutationVariables
  >(EnrollPracticeToLoyaltyProgramDocument);

  useEffect(() => {
    dispatch(fetchLoyaltyPrograms());
  }, []);

  const handleSubmit = async () => {
    const result =
      selectedLoyaltyProgram &&
      selectedLoyaltyProgramTier &&
      (await enrollPracticeToLoyaltyProgram({
        practiceId: Number(practiceId),
        loyaltyProgramId: selectedLoyaltyProgram.value.id,
        startingTierId: selectedLoyaltyProgramTier.value.id,
      }));
    if (result?.addPracticeToLoyaltyProgram?.practiceLoyaltyStatus) {
      showNotification('Practice enrolled successfully', 'success');
      dispatch(
        setSelectedPracticeLoyaltyStatus({
          getCurrentPracticeLoyaltyProgram:
            result.addPracticeToLoyaltyProgram.practiceLoyaltyStatus,
        })
      );
    }
    setSelectedLoyaltyProgram(undefined);
    setSelectedLoyaltyProgramTier(undefined);
  };

  return (
    <Container>
      <h3>Edit loyalty settings</h3>
      <Flex direction="column">
        <Flex direction="column">
          <Label>Select loyalty program</Label>
          <LoyaltySelect
            options={loyaltyProgramOptions}
            onChange={(selection: LoyaltySelectOption) => {
              setSelectedLoyaltyProgram(selection);
              setSelectedLoyaltyProgramTier(undefined);
            }}
            value={selectedLoyaltyProgram}
            width={'100%'}
            isDisabled={!canEdit}
          />
        </Flex>
        <Flex direction="column">
          <Label>Select tier</Label>
          <LoyaltySelect
            options={loyaltyProgramTierOptions}
            onChange={(selection: LoyaltySelectOption) =>
              setSelectedLoyaltyProgramTier(selection)
            }
            value={selectedLoyaltyProgramTier}
            width={'100%'}
            isDisabled={!canEdit}
          />
        </Flex>
      </Flex>
      <StyledButton
        onClick={handleSubmit}
        buttonType="secondary"
        disabled={
          !selectedLoyaltyProgram || !selectedLoyaltyProgramTier || !canEdit
        }
        isLoading={loading}
      >
        Submit and enroll
      </StyledButton>
    </Container>
  );
};

export default LoyaltyEnrollmentForm;
