import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Popover, NotificationContext } from '@candidco/enamel';

import Check from 'assets/check-blue.svg?react';
import TripleDotSvg from 'assets/ic_more-vertical.svg?react';
import DeliverySvg from 'assets/ic_package.svg?react';
import MonitoringSvg from 'assets/ic_eye.svg?react';
import FileSvg from 'assets/ic_file.svg?react';
import { useIsLoading } from 'state/system';

import {
  selectEmail,
  selectCustomer,
  selectSelectedCase,
  fetchCustomer,
  fetchCases,
  selectCases,
  selectIsEligibleForRefinements,
  selectSubsequentCase,
  selectLatestTreatmentPlanStaging,
  selectBypassOrthoReview,
  cancelCoreCase,
} from 'pages/OrthoPrism/orthoSlice';
import CustomerActions from 'components/PatientHeader/CustomerActions';
import RefinementsRequestedAlertCard from 'components/AlertCards/RefinementsRequestedAlertCard';
import { searchDMPatientsURL } from 'utils/url';
import { useGQLMutation } from 'hooks/useGQL';
import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import { JourneyTransition } from 'pages/Case/types';
import { ArchiveCaseModal, CompleteCaseConfirmation } from 'components/Modals';
import OpenInActions from 'components/PatientHeader/OpenInActions';
import {
  ALIGNER_JOURNEY_TYPE,
  ALIGNER_PRODUCTION_LEG,
} from 'pages/Case/CaseProvider';
import {
  Container,
  ActionsContainer,
  Overline,
  JourneyStateContainer,
  JourneyState,
  Label,
  Checkbox,
  HighLightedButton,
  StyledArrow,
  ButtonRow,
  StyledButton,
  AdditionalItemsMenu,
  LinkItem,
  StyledAlertCard,
  CaseInfoContainer,
  AlertContainer,
} from 'pages/OrthoPrism/CaseSummary.css';
import { PROVIDER_FACING_STATUSES } from 'constants/caseStatus';
import {
  CaseSource,
  MaterialStates,
  CreatePatientDmAccountMutation,
  CreatePatientDmAccountMutationVariables,
  CreatePatientDmAccountDocument,
  SendDmActivationEmailMutation,
  SendDmActivationEmailMutationVariables,
  SendDmActivationEmailDocument,
} from 'generated/core/graphql';
import useMarkAkAsDelivered from 'hooks/useMarkAkAsDelivered';
import CaseActions from 'pages/OrthoPrism/CaseActions';
import StagingActions from 'pages/OrthoPrism/StagingActions';
import { getBrandDomainSettings } from 'utils/brands';
import { availableActionsForProviderFacingStatus } from 'utils/case';
import { useFlags } from 'launchdarkly-react-client-sdk';

type CaseSummaryProps = {
  setIsOrderShippingModalOpen: (value: React.SetStateAction<boolean>) => void;
};

const CaseSummary = ({ setIsOrderShippingModalOpen }: CaseSummaryProps) => {
  const { markAkAsDelivered, isLoading: isMarkingAkAsDelivered } =
    useMarkAkAsDelivered();
  const dispatch = useDispatch();
  const { showNotification } = useContext(NotificationContext);
  const selectedCustomer = useSelector(selectCustomer);
  const subsequentCase = useSelector(selectSubsequentCase);
  const cases = useSelector(selectCases);
  const selectedCase = useSelector(selectSelectedCase);
  const bypassOrthoReview = useSelector(selectBypassOrthoReview);
  const latestTreatmentPlanStaging = useSelector(
    selectLatestTreatmentPlanStaging
  );

  const caseEligibleForRefinements = useSelector(
    selectIsEligibleForRefinements
  );
  const email = useSelector(selectEmail);
  const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false);
  const [isModalAcknowledged, setIsModalAcknowledged] = useState(false);
  const [isArchiveCaseModalOpen, setIsArchiveCaseModalOpen] = useState(false);
  const isFetchingCustomerInfo = useIsLoading(fetchCustomer.type);
  const isFetchingCases = useIsLoading(fetchCases.typePrefix);
  const { monitoringLabel } = getBrandDomainSettings();
  const { 'white-labeling-brand-support': whiteLabeling } = useFlags();

  const [createPatientDmAccountFn, { loading: isCreatingPatientDmAccount }] =
    useGQLMutation<
      CreatePatientDmAccountMutation,
      CreatePatientDmAccountMutationVariables
    >(CreatePatientDmAccountDocument, true);
  const [sendActivationEmailFn, { loading: isSendingActionEmail }] =
    useGQLMutation<
      SendDmActivationEmailMutation,
      SendDmActivationEmailMutationVariables
    >(SendDmActivationEmailDocument, true);

  const customerId = selectedCustomer?.id as string;
  const [transitionJourney, { loading: isTransistionJourneySubmitting }] =
    useTransitionJourneyMutation({
      onCompleted: () => {
        refetchCustomerAndCases();
      },
    });

  const refetchCustomerAndCases = () => {
    dispatch(
      fetchCustomer({
        customerId,
      })
    );
    dispatch(
      fetchCases({
        patientIds: [Number(customerId)],
      })
    );
  };
  if (!selectedCase) {
    return null;
  }

  const {
    caseState,
    caseRef,
    isActive,
    shortCaseRef,
    manufacturerExternalRef,
  } = selectedCase;

  const completeCase = () => {
    const journey = ALIGNER_PRODUCTION_LEG;
    if (selectedCase.source === CaseSource.CaseService) {
      transitionJourney({
        variables: {
          caseRef,
          component: journey,
          transition: JourneyTransition.Complete,
        },
      });
    } else {
      showNotification(
        'Unable to complete case. Please reach out to support for assistance',
        'error'
      );
    }
    setIsCompleteModalVisible(false);
  };

  const cancelCase = (archiveReason: string) => {
    const journey = ALIGNER_JOURNEY_TYPE;
    if (selectedCase.source === CaseSource.CaseService) {
      transitionJourney({
        variables: {
          caseRef,
          component: journey,
          transition: JourneyTransition.ForceCaseCanceled,
          transitionReason: archiveReason,
        },
      });
    } else if (selectedCase.source === CaseSource.Core) {
      dispatch(
        cancelCoreCase({
          caseRef: selectedCase?.caseRef,
          reason: archiveReason,
        })
      );
      refetchCustomerAndCases();
    } else {
      showNotification(
        'Unable to cancel case. Please reach out to support for assistance',
        'error'
      );
    }
  };

  const updateAkToDelivered = async () => {
    if (selectedCase.source === CaseSource.CaseService && selectedCustomer) {
      try {
        await markAkAsDelivered({ caseRef, patient: selectedCustomer });
        refetchCustomerAndCases();
      } catch (error) {
        if (error instanceof Error) {
          showNotification(error.message, 'error');
        }
      }
    } else {
      showNotification(
        'Unable to move case to Aligner Kit Delivered/In Treatment. Please reach out to support for assistance',
        'error'
      );
    }
  };

  const createPatientDmAccount = () => {
    createPatientDmAccountFn({
      patientId: customerId,
    })
      .then((data) => {
        showNotification(
          `Dental Monitoring account created for patient: ${data?.createPatientDmAccount?.patient?.externalId}`,
          'success'
        );
      })
      .catch((error) => {
        showNotification(error?.message, 'error');
      });
  };

  const sendActivationEmail = () => {
    sendActivationEmailFn({
      patientId: customerId,
    })
      .then((data) => {
        showNotification(
          `Activation email sent to ${data?.sendDmActivationEmail?.response?.to?.email}`,
          'success'
        );
      })
      .catch((error) => {
        showNotification(error?.message, 'error');
      });
  };

  const isLoading =
    isTransistionJourneySubmitting ||
    isFetchingCustomerInfo ||
    isFetchingCases ||
    isMarkingAkAsDelivered;

  const patientInTreatment =
    caseState?.internal === PROVIDER_FACING_STATUSES.IN_TREATMENT;

  const isTreatmentPlanningInProgress =
    !!isActive &&
    caseState?.internal ===
      PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS;
  const canArchive =
    !!isActive &&
    caseState?.treatmentPlanStaging?.state !== MaterialStates.Completed;

  const MonitoringLink = `${searchDMPatientsURL(whiteLabeling)}${encodeURIComponent(email)}`;

  const {
    canCreateDmAccount,
    canSendDmActivationEmail,
    canConfirmAlignerDelivery,
    canViewTpLinkPdf,
  } = availableActionsForProviderFacingStatus(
    caseState?.internal as PROVIDER_FACING_STATUSES
  );

  const showTpLinkPdf = latestTreatmentPlanStaging?.pdfUrl && canViewTpLinkPdf;

  const showCreateDmAccount =
    !selectedCustomer?.dmGatewayId && canCreateDmAccount;
  const showSendActivationEmail =
    selectedCustomer?.dmGatewayId && canSendDmActivationEmail;

  return (
    <>
      <ArchiveCaseModal
        isOpen={isArchiveCaseModalOpen}
        inPlanning={isTreatmentPlanningInProgress}
        onClose={() => {
          setIsArchiveCaseModalOpen(false);
        }}
        onConfirm={(archiveReason: string) => {
          setIsArchiveCaseModalOpen(false);
          cancelCase(archiveReason);
        }}
      />
      <Container>
        <Overline data-testid="overline-heading">
          Case #{shortCaseRef}
          {bypassOrthoReview && ' • NO ORTHO REVIEW'}
        </Overline>
        <JourneyStateContainer>
          <JourneyState data-testid="ortho-prism-case-info">
            {caseState?.internal}
          </JourneyState>

          {selectedCustomer && (
            <ActionsContainer>
              <>
                <StagingActions />
                <CaseActions
                  customerId={customerId}
                  selectedCase={selectedCase}
                  showNotification={showNotification}
                />
              </>
              <CustomerActions
                customerId={customerId}
                cases={cases}
                refetchCases={(patientId: string) => {
                  dispatch(fetchCases({ patientIds: [Number(patientId)] }));
                }}
              />
              <OpenInActions customerId={customerId} caseRef={caseRef} />
            </ActionsContainer>
          )}
        </JourneyStateContainer>

        {!isActive && (
          <RefinementsRequestedAlertCard
            currentStep={subsequentCase?.data?.currentStep ?? undefined}
            daysOnCurrentStep={
              subsequentCase?.data?.daysOnCurrentStep ?? undefined
            }
            providerFacingState={selectedCase?.caseState?.providerFacing}
            itiType={subsequentCase?.data?.itiType}
            hasDentalWorkBeenDone={
              !!subsequentCase?.data?.hasDentalWorkBeenDone
            }
            notes={subsequentCase?.data?.notes}
            paymentCategory={subsequentCase?.data?.itiPaymentCategory}
          />
        )}

        <ButtonRow>
          {patientInTreatment && (
            <StyledButton
              buttonType="secondary-outline"
              disabled={
                isTransistionJourneySubmitting ||
                isFetchingCustomerInfo ||
                isFetchingCases
              }
              isLoading={isLoading}
              onClick={() => {
                setIsCompleteModalVisible(true);
              }}
            >
              <Check style={{ marginBottom: '3px' }} /> Mark as complete
            </StyledButton>
          )}

          {canConfirmAlignerDelivery && (
            <StyledButton
              buttonType="secondary-outline"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => updateAkToDelivered()}
              leftIcon={<DeliverySvg />}
            >
              Confirm aligner delivery
            </StyledButton>
          )}
          {canArchive && (
            <Button
              buttonType="primary-outline"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => {
                setIsArchiveCaseModalOpen(true);
              }}
            >
              Archive this case
            </Button>
          )}
          {showSendActivationEmail && (
            <StyledButton
              buttonType="secondary-outline"
              isLoading={isSendingActionEmail}
              onClick={() => sendActivationEmail()}
            >
              Resend activation email
            </StyledButton>
          )}
          {showCreateDmAccount && (
            <StyledButton
              buttonType="secondary-outline"
              isLoading={isCreatingPatientDmAccount}
              onClick={() => createPatientDmAccount()}
            >
              Create DM account
            </StyledButton>
          )}

          {(patientInTreatment || showTpLinkPdf) && (
            <Popover direction="right" on="click" anchor={<TripleDotSvg />}>
              <AdditionalItemsMenu>
                {showTpLinkPdf && (
                  <LinkItem>
                    <FileSvg />
                    <button
                      onClick={() => {
                        if (latestTreatmentPlanStaging.pdfUrl) {
                          window.open(latestTreatmentPlanStaging.pdfUrl);
                        }
                      }}
                    >
                      View/print Treatment Plan PDF
                    </button>
                  </LinkItem>
                )}
                {patientInTreatment && (
                  <LinkItem>
                    <MonitoringSvg />
                    <a
                      href={MonitoringLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {`View in ${monitoringLabel}`}
                    </a>
                  </LinkItem>
                )}
              </AdditionalItemsMenu>
            </Popover>
          )}
        </ButtonRow>
        {!caseEligibleForRefinements && patientInTreatment && (
          <AlertContainer>
            <StyledAlertCard
              width={'100%'}
              type="warning"
              displayIcon={true}
              header="Cases older than three years no longer qualify for refinements"
            />
          </AlertContainer>
        )}
        <CaseInfoContainer>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            data-testid="ortho-prism-case-info"
          >
            <Grid item xs={6} md={4}>
              <Overline data-testid="overline-heading">
                {monitoringLabel}
              </Overline>
              <div>
                <a
                  href={`${searchDMPatientsURL(whiteLabeling)}${encodeURIComponent(email)}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Open
                  <StyledArrow aria-hidden />
                </a>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <Overline data-testid="overline-heading">
                Orders &amp; shipping
              </Overline>
              <div>
                <HighLightedButton
                  onClick={() => setIsOrderShippingModalOpen(true)}
                >
                  View orders
                  <StyledArrow aria-hidden />
                </HighLightedButton>
              </div>
            </Grid>
            {manufacturerExternalRef && (
              <Grid item xs={6} md={4}>
                <Overline>Supplier Case ID</Overline>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://${import.meta.env.VITE_REACT_APP_ENV === 'production' ? '' : 'dev-'}api.kraken.dental/admin/cases/case/?q=${manufacturerExternalRef}`}
                >
                  {manufacturerExternalRef}
                </a>
              </Grid>
            )}
          </Grid>
        </CaseInfoContainer>
      </Container>
      <CompleteCaseConfirmation
        confirmButtonText={'Submit'}
        isLoading={isLoading}
        isOpen={isCompleteModalVisible}
        onConfirm={completeCase}
        onCancel={() => {
          setIsCompleteModalVisible(false);
        }}
        onClose={() => {
          setIsCompleteModalVisible(false);
        }}
        isConfirmDisabled={!isModalAcknowledged}
        headerText="Mark treatment as complete"
      >
        By marking this case as complete, I acknowledge that the patient has met
        my clinical goals for their treatment.
        <Label>
          <Checkbox
            checked={isModalAcknowledged}
            onChange={() => setIsModalAcknowledged(!isModalAcknowledged)}
            type="checkbox"
          />
          Acknowledge
        </Label>
      </CompleteCaseConfirmation>
    </>
  );
};

export default CaseSummary;
