import styled from 'styled-components/macro';
import Snackbar from '@material-ui/core/Snackbar';
import { type } from '@candidco/enamel';

export const Page = styled.div`
  max-width: 1080px;
  margin: 32px auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0;
  }
`;

export const Header = styled.h3`
  ${({ theme }) => theme.text.headings.h3}
  margin-bottom: 32px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    display: none;
  }
`;

export const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    min-width: 0;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.text90};
  }
`;

export const Sidebar = styled.div`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    display: block;
    top: 120px;
    position: sticky;
    height: 100%;
    padding-left: 16px;
    width: 200px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  // Some extra padding on the bottom so anchor links scroll to the right spot
  padding-bottom: 50vh;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const FormSections = styled.div`
  position: relative;
  margin: 0 32px;

  @media ${({ theme }) => theme.mediaQueries.desktop} {
    max-width: 760px;
    margin: 0 16px;
    top: 80px;
  }
`;

export const SidebarLink = styled.a`
  display: block;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.text90};
  line-height: 150%;

  :hover {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text90};
  }
`;

export const SidebarTitle = styled(type.Overline)`
  color: ${({ theme }) => theme.colors.black70};
  font-weight: 500;
  margin-bottom: 16px;
  letter-spacing: 0.48px;
  line-height: 120%;

  :not(:first-child) {
    margin-top: 32px;
  }
`;
