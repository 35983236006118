import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsLoading } from 'state/system';
import {
  colors,
  Tooltip,
  Divider,
  Heading,
  Text,
  Loading,
  Grid,
} from '@candidco/enamel';
import Snackbar from '@material-ui/core/Snackbar';

import { useAuthContext } from 'components/AuthProvider';
import {
  JourneyStatus,
  AccordionTaskTableProps,
  DefinitionsMapType,
} from 'pages/ActionItems/types';
import DocumentTitle from 'components/DocumentTitle';
import { usePartnerOrgs } from 'hooks/usePartnerOrgs';
import TaskTable from 'pages/ActionItems/TaskTable';
import { OverviewModule } from 'pages/ActionItems/OverviewModules/Overview';
import { NoTaskMessage } from 'pages/ActionItems/EmptyStates';
import { definitionsMap } from 'pages/ActionItems/OverviewModules/definitions';
import { useHistory } from 'react-router-dom';
import { SkeletonTable } from 'pages/ActionItems/Skeletons';

import {
  selectIncompleteSubmissionTasks,
  selectTpReadyForReviewTasks,
  selectMaterialsRejectedTasks,
  selectNeedsClarificationTasks,
  selectProviderTaskCounts,
  getActionItemsOverview,
} from 'pages/ActionItems/actionItemsSlice';

import { resetPatientState } from 'pages/Patient/patientSlice';

import TourBanner from 'pages/ActionItems/TourBanner';
import LoyaltyAlert from 'pages/ActionItems/Alerts/LoyaltyAlert';

import {
  StyledButton,
  TextAndIconContainer,
  ButtonTextContainer,
  PatientIcon,
  SectionContainer,
  DashboardContainer,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAccordion,
  StyledChip,
  SnackbarAction,
  OverviewContainer,
  ItemsContainer,
  AccordionContainer,
  LoadingContainer,
  EmptyStateContainer,
  StyledNoPatientsImage,
  PaddedItem,
  TextItem,
  InvisibleAnchorTag,
} from 'pages/ActionItems/ActionItems.css';
import { AppcuesFlows } from 'constants/appcues';
import { ActionItemTask } from 'generated/core/graphql';
import NewPatientModal from 'pages/ActionItems/NewPatientModal/NewPatientModal';
import NoPatientsImage from 'assets/no-patients.png';

const FIFTEEN_MINS = 15 * 60 * 1000;

const reloadPage = () => window.location.reload();

type NewPatientButtonProps = {
  onClick: () => void;
};

export const NewPatientButton = ({ onClick }: NewPatientButtonProps) => {
  return (
    <StyledButton
      buttonType="secondary"
      onClick={onClick}
      data-testid="add-new-patient-button"
    >
      <TextAndIconContainer>
        <PatientIcon className={'add-new-patient-icon'} />
        <ButtonTextContainer>New patient</ButtonTextContainer>
      </TextAndIconContainer>
    </StyledButton>
  );
};

const refreshAction = (
  <SnackbarAction buttonType="secondary-outline" isShort onClick={reloadPage}>
    REFRESH
  </SnackbarAction>
);

const AccordionTaskTable = ({
  expanded,
  onChange,
  tasks,
  showSkeleton,
  journeyStatus,
  title,
  chipColor,
  chipTextColor,
}: AccordionTaskTableProps) => {
  if (showSkeleton) {
    return <SkeletonTable />;
  }

  // Only display table when there is data
  if (tasks.length > 0) {
    return (
      <StyledAccordion expanded={expanded} onChange={onChange}>
        <StyledAccordionSummary>
          <Heading
            variant={'h4'}
            data-testid="action-item-section-header"
            style={{ textAlign: 'left' }}
          >
            <InvisibleAnchorTag id={title} />
            {title}
            <Tooltip
              title={
                <Text variant={'small'}>
                  {definitionsMap[title as keyof DefinitionsMapType].definition}
                </Text>
              }
              placement="top"
            >
              <StyledChip
                color={chipTextColor}
                customColor={chipColor}
                size="tiny"
                label={tasks.length}
              />
            </Tooltip>
          </Heading>
        </StyledAccordionSummary>
        <Divider />
        <StyledAccordionDetails data-testid={`${journeyStatus}-table`}>
          <TaskTable
            journeyStatus={journeyStatus}
            tasks={tasks}
            isFetching={false}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
    );
  }

  return null;
};

const ActionItems = () => {
  const { userInfo } = useAuthContext();
  const { push, location } = useHistory();
  const dispatch = useDispatch();

  const [isStale, setStale] = useState(false);
  const [showNoPatients, setShowNoPatients] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [allExpanded, setAllExpanded] = useState({
    incompleteSubmission: true,
    tpReadyForReview: true,
    materialsRejected: true,
    needsClarification: true,
  });
  const [tourBannerSnoozed, setTourBannerSnoozed] = useState<boolean>(false);
  const [showNewPatientModal, setShowNewPatientModal] =
    useState<boolean>(false);

  const incompleteSubmissionTasks = useSelector(
    selectIncompleteSubmissionTasks
  );
  const materialsRejectedTasks = useSelector(selectMaterialsRejectedTasks);
  const needsClarificationTasks = useSelector(selectNeedsClarificationTasks);
  const tpReadyForReviewTasks = useSelector(selectTpReadyForReviewTasks);
  const providerTaskCounts = useSelector(selectProviderTaskCounts);
  const { partnerOrgs } = usePartnerOrgs();

  const isLoading = useIsLoading(getActionItemsOverview.type);

  const actionTaskLists = [
    incompleteSubmissionTasks,
    materialsRejectedTasks,
    needsClarificationTasks,
    tpReadyForReviewTasks,
  ];

  const doctorId = userInfo?.doctor?.id;

  const noTasks =
    fetchedData &&
    !isLoading &&
    actionTaskLists.every(
      (taskList: ActionItemTask[] | null) => !!taskList && taskList.length === 0
    );

  //If there's no data, but we're loading.
  const isLoadingFirstTime =
    actionTaskLists.some((t) => t === null) && isLoading;

  //Show the skeleton if no data has been fetched or we're loading data for the first time
  const showSkeleton = (
    isLoading: boolean,
    data: ActionItemTask[] | any[] | null
  ) => !fetchedData || (data === null && (isLoading || isLoading));

  const loadInitialTasks = () => {
    if (doctorId) {
      const parsedDoctorId = parseInt(doctorId);

      dispatch(
        getActionItemsOverview({
          referringDentistId: parsedDoctorId,
        })
      );
      setFetchedData(true);
    }

    const timer = setTimeout(() => {
      setStale(true);
    }, FIFTEEN_MINS);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    dispatch(
      resetPatientState() // Reset patient state to clear out any data from previous patient
    );
  }, [dispatch]);

  useEffect(() => {
    if (providerTaskCounts) {
      const totalCases = providerTaskCounts.find(
        (taskCount) => taskCount.state === 'Total cases'
      );

      if (totalCases?.count === 0) {
        setShowNoPatients(true);
      }

      //Appcues works on page transitions. Normally we would want to show up on page load, and it would happen automatically,
      //But because the demo relies on data being loaded we do this
      //The flow is setup to listen for url action-items?loaded. The ?loaded gets added when the data has been added, and there's some data
      if (fetchedData && providerTaskCounts.length) {
        const params = new URLSearchParams(location.search);
        if (params.get('take-the-tour') === 'true') {
          window.Appcues.show(AppcuesFlows.ACTION_ITEMS_TOUR);
        } else {
          push('?loaded'); //Append the loaded querystring
          window.Appcues.page(); //Let appcues know the page has changed
        }
      }
    }
  }, [providerTaskCounts]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    if (userInfo.doctor?.id) {
      return loadInitialTasks();
    } else {
      push('/');
    }
  }, [userInfo]);

  const createNewPatient = () => {
    setShowNewPatientModal(true);
  };

  const NoPatientsToDisplay = () => {
    return (
      <EmptyStateContainer container>
        <Grid item>
          <StyledNoPatientsImage src={NoPatientsImage} />
        </Grid>
        <PaddedItem item>
          <Heading variant={'h4'}>No patients to display</Heading>
        </PaddedItem>
        <TextItem item>
          <Text>
            Once you have patients in your account, you’ll see them here if
            action is needed.
          </Text>
        </TextItem>
        <PaddedItem item>
          <NewPatientButton onClick={createNewPatient} />
        </PaddedItem>
      </EmptyStateContainer>
    );
  };

  const ActionItemsBody = () => {
    if (showNoPatients) {
      return <NoPatientsToDisplay />;
    }

    return (
      <DashboardContainer container data-testid="dashboard-container">
        <ItemsContainer>
          <OverviewContainer>
            <SectionContainer data-testid="overview">
              <OverviewModule
                providerTaskCounts={providerTaskCounts}
                isFetchingProviderTasks={isLoading}
                showSkeleton={showSkeleton}
                onNewPatientClick={createNewPatient}
              />
            </SectionContainer>
          </OverviewContainer>
          <AccordionContainer>
            {partnerOrgs.length === 0 && (
              <LoyaltyAlert setTourBannerSnoozed={setTourBannerSnoozed} />
            )}
            <SectionContainer>
              {fetchedData && // At the start nothing is being fetched, so nothing is loading
                !isLoading && //Once everything has stopped fetching
                !noTasks && ( //There are items in the action items
                  <TourBanner
                    setTourBannerSnoozed={setTourBannerSnoozed}
                    tourBannerSnoozed={tourBannerSnoozed}
                  />
                )}
              {!isLoadingFirstTime && isLoading && (
                <LoadingContainer>
                  <div>Refreshing data</div>
                  <Loading />
                </LoadingContainer>
              )}
              <AccordionTaskTable
                expanded={allExpanded.tpReadyForReview}
                onChange={() =>
                  setAllExpanded({
                    ...allExpanded,
                    tpReadyForReview: !allExpanded.tpReadyForReview,
                  })
                }
                tasks={tpReadyForReviewTasks ?? []}
                showSkeleton={showSkeleton(isLoading, tpReadyForReviewTasks)}
                journeyStatus={JourneyStatus.tpReadyForReview}
                title="Treatment plan ready for your review"
                chipColor={colors.orange50}
              />
              <AccordionTaskTable
                expanded={allExpanded.materialsRejected}
                onChange={() =>
                  setAllExpanded({
                    ...allExpanded,
                    materialsRejected: !allExpanded.materialsRejected,
                  })
                }
                tasks={materialsRejectedTasks ?? []}
                showSkeleton={showSkeleton(isLoading, materialsRejectedTasks)}
                journeyStatus={JourneyStatus.materialsRejected}
                title="Materials rejected"
                chipColor={colors.red50}
                chipTextColor="secondary"
              />
              <AccordionTaskTable
                expanded={allExpanded.needsClarification}
                onChange={() =>
                  setAllExpanded({
                    ...allExpanded,
                    needsClarification: !allExpanded.needsClarification,
                  })
                }
                tasks={needsClarificationTasks ?? []}
                showSkeleton={showSkeleton(isLoading, needsClarificationTasks)}
                journeyStatus={JourneyStatus.needsClarification}
                title="Needs clarification"
                chipColor={colors.yellow70}
              />
              <AccordionTaskTable
                expanded={allExpanded.incompleteSubmission}
                onChange={() =>
                  setAllExpanded({
                    ...allExpanded,
                    incompleteSubmission: !allExpanded.incompleteSubmission,
                  })
                }
                tasks={incompleteSubmissionTasks ?? []}
                showSkeleton={showSkeleton(
                  isLoading,
                  incompleteSubmissionTasks
                )}
                journeyStatus={JourneyStatus.incompleteSubmission}
                title="Incomplete submission"
                chipColor={colors.black30}
              />

              {noTasks && <NoTaskMessage />}
            </SectionContainer>
          </AccordionContainer>
        </ItemsContainer>
        <Snackbar
          open={isStale}
          message="Page data may be outdated"
          action={refreshAction}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        />
      </DashboardContainer>
    );
  };

  return (
    <DocumentTitle title="Action items">
      <NewPatientModal
        isOpen={showNewPatientModal}
        onClose={() => {
          setShowNewPatientModal(false);
        }}
      />
      <ActionItemsBody />
    </DocumentTitle>
  );
};

export default ActionItems;
