import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { colors, Grid, theme, Tooltip } from '@candidco/enamel';
import { AuthContext } from 'components/AuthProvider';
import { useIsOnlyCandidPro } from 'hooks/useIsOnlyCandidPro';
import { useIsOrtho } from 'hooks/useIsOrtho';
import { useAuthContext } from 'components/AuthProvider';
import { usePartnerOrgs } from 'hooks/usePartnerOrgs';
import { ACCESS_GROUPS } from 'constants/index';
import { isPracticeGrandfathered } from 'pages/LoyaltyProviderView/utilities';

import {
  LaunchNotesApiToken,
  LaunchNotesProjectId,
} from 'constants/launchnotes';

import BellSvg from 'assets/fi_bell.svg?react';
import HelpCircle from 'assets/ic_help-circle.svg?react';

import { UserMenu } from 'components/Header/UserMenu';
import { DesktopHeaderNav, MobileHeaderNav } from 'components/Header/HeaderNav';
import {
  Content,
  GlobalStyles,
  Wrap,
  MenuButtonContainer,
  MobileParent,
  DesktopParent,
  LaunchNotesNotificationContainer,
  LaunchNotesEmbedContainer,
  ZendeskWidgetContainer,
  HelpCircleContainer,
  VerticalDeskptopParent,
} from 'components/Header/Header.css';
import { ALIGNER_SKU } from 'constants/loyalty';
import { LoyaltyInfo } from 'components/Header/types';
import HeaderLogo from 'components/Header/Logo';
import ZendeskWidget from 'components/ZendeskWidget';
import { getBrandDomainSettings } from 'utils/brands';
import ImpersonationBanner from 'components/Header/ImpersonationBanner';
import EnvironmentBanner from 'components/Header/EnvironmentBanner';

const PRO_LABEL = 'Professional';
const ORTHO_LABEL = 'Orthodontic';

const Header = () => {
  const headerNavRoutes = [
    '/action-items',
    '/ortho-prism',
    '/case',
    '/marketing-resources',
    '/loyalty',
    '/patient',
    '/preferences',
  ];
  const { userInfo, currentPracticeLoyaltyTier, loyaltyQuotesResult } =
    useContext(AuthContext);
  const { partnerOrgs } = usePartnerOrgs();
  const hasPartnerOrg = partnerOrgs.length > 0;
  const isOnlyPro = useIsOnlyCandidPro();
  const isOrtho = useIsOrtho();
  const { checkHasAccess } = useAuthContext();
  const { pathname } = useLocation();
  const [displayPrice, setDisplayPrice] = useState<boolean | null>(null);
  const { showLaunchNotes } = getBrandDomainSettings();
  const LaunchNotesId = 'LaunchNotesContainer';

  useEffect(() => {
    if (userInfo && !currentPracticeLoyaltyTier) {
      // Current requirement is to only show loyalty info if the doctor only has one practice, which must not be part of a DSO
      // AND the doctor does not have any partner organization relationship (ex. Glidewell)
      if (userInfo?.doctor?.practices?.length === 1 && !hasPartnerOrg) {
        const practice = userInfo?.doctor?.practices[0];
        if (!practice.parentOrganization) {
          setDisplayPrice(true);
        } else {
          setDisplayPrice(false);
        }
      }
    }
  }, [userInfo, hasPartnerOrg]);

  useEffect(() => {
    if (loyaltyQuotesResult) {
      setDisplayPrice(!isPracticeGrandfathered(loyaltyQuotesResult));
    }
  }, [loyaltyQuotesResult]);

  const price =
    loyaltyQuotesResult
      ?.find((q) => q?.tierName === currentPracticeLoyaltyTier?.name)
      ?.products?.find((p) => p?.sku === ALIGNER_SKU)?.quote || null;

  const loyaltyInfo: LoyaltyInfo = {
    name: currentPracticeLoyaltyTier?.name || '',
    sortOrder: currentPracticeLoyaltyTier?.sortOrder || 0,
    price: price,
    displayPrice: displayPrice,
    enableLoyaltyProviderView: !hasPartnerOrg,
  };

  const ProNavRoute = {
    // List of override routes based on tool
    'action-items': PRO_LABEL,
    'customer-creator': PRO_LABEL,
    'marketing-resources': PRO_LABEL,
    tasks: ORTHO_LABEL,
  };

  const logoSubString: string | undefined = useMemo(() => {
    let logoLabel;
    if (isOnlyPro) {
      logoLabel = PRO_LABEL;
    } else if (isOrtho) {
      logoLabel = ORTHO_LABEL;
    }

    // Check for overrides by route
    for (const [key, value] of Object.entries(ProNavRoute)) {
      if (pathname.includes(key) && !pathname.includes('tasks/providers')) {
        logoLabel = value;
      }
    }
    return logoLabel;
  }, [pathname]);

  const showHeaderNavLinks =
    checkHasAccess(ACCESS_GROUPS.CANDID_PRO) &&
    headerNavRoutes.some((route) => pathname.includes(route));

  const { helpCenterUrl: zendeskHelpCenterUrl } = getBrandDomainSettings();

  // `/patient/[id]/case-creator` has its own special header override
  if (/^\/patient\/\d+\/case-creator/.test(pathname)) {
    return null;
  }

  return (
    <Wrap>
      <GlobalStyles />
      <Content>
        <MobileParent>
          <Grid container direction="row" justifyContent="space-between">
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {showHeaderNavLinks && (
                <MobileHeaderNav
                  logoSubString={logoSubString}
                  loyaltyInfo={loyaltyInfo}
                />
              )}
            </Grid>

            <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <HeaderLogo
                logoSubString={logoSubString}
                loyaltyTier={currentPracticeLoyaltyTier}
                isCentered
              />
            </Grid>

            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <UserMenu
                logoSubString={logoSubString}
                loyaltyInfo={loyaltyInfo}
              />
            </Grid>
          </Grid>
        </MobileParent>
        <VerticalDeskptopParent>
          <ImpersonationBanner />
          <EnvironmentBanner />
          <DesktopParent>
            <HeaderLogo
              logoSubString={logoSubString}
              loyaltyTier={currentPracticeLoyaltyTier}
            />

            {showHeaderNavLinks && <DesktopHeaderNav />}

            <MenuButtonContainer>
              <Tooltip title="Help" color={theme.colors.black90}>
                <HelpCircleContainer>
                  <HelpCircle
                    stroke={colors.black70}
                    role="img"
                    onClick={() => window.open(zendeskHelpCenterUrl as string)}
                    style={{ cursor: 'pointer' }}
                  />
                </HelpCircleContainer>
              </Tooltip>
              {!!userInfo?.email && showLaunchNotes && (
                <>
                  <Tooltip title="Announcements" color={theme.colors.black90}>
                    <LaunchNotesNotificationContainer id={LaunchNotesId}>
                      <BellSvg />
                    </LaunchNotesNotificationContainer>
                  </Tooltip>
                  <LaunchNotesEmbedContainer>
                    <launchnotes-embed
                      token={LaunchNotesApiToken}
                      project={LaunchNotesProjectId}
                      heading="Our latest updates"
                      heading-color={colors.white}
                      subheading="Here’s what we’ve been up to"
                      subheading-color={colors.blue05}
                      primary-color={theme.colors.blue70}
                      toggle-selector={`#${LaunchNotesId}`}
                      widget-placement="bottom"
                      widget-offset-distance="30"
                      show-unread={true}
                      unread-placement="right-start"
                      unread-offset-skidding="-2"
                      unread-offset-distance="-10"
                      unread-background-color={colors.red50}
                      unread-text-color={colors.blue05}
                      anonymous-user-id={userInfo?.email!}
                    />
                  </LaunchNotesEmbedContainer>
                </>
              )}
              <Tooltip title="Live chat">
                <ZendeskWidgetContainer>
                  <ZendeskWidget />
                </ZendeskWidgetContainer>
              </Tooltip>

              <UserMenu
                logoSubString={logoSubString}
                loyaltyInfo={loyaltyInfo}
              />
            </MenuButtonContainer>
          </DesktopParent>
        </VerticalDeskptopParent>
      </Content>
    </Wrap>
  );
};

export default Header;
