import React from 'react';
import { Chip, colors } from '@candidco/enamel';
import styled from 'styled-components/macro';

const Banner = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  gap: 8px;
  height: 50px;
  font-weight: 500;
`;

type AvailableEnvs = 'qa' | 'staging';

const envTextMapping = {
  qa: 'QA',
  staging: 'Staging',
};

const envColorMapping = {
  staging: colors.yellow50,
  qa: colors.orange30,
};

const EnvironmentBanner = () => {
  const currentEnvironment: AvailableEnvs = import.meta.env.VITE_REACT_APP_ENV;

  if (currentEnvironment !== 'qa' && currentEnvironment !== 'staging') {
    return null;
  }

  return (
    <Banner color={envColorMapping[currentEnvironment]}>
      <div>Environment</div>
      <Chip
        label={envTextMapping[currentEnvironment]}
        size="small"
        variant="outlined"
      />
    </Banner>
  );
};

export default EnvironmentBanner;
