import { QualityTickets, QualityTicketsQuery } from 'generated/core/graphql';
import React, { useEffect, useState } from 'react';
import {
  CategoryBadge,
  OverflowCategoryBadge,
  StatusBadge,
  TicketContainer,
  RowContainer,
  RowItem,
  CategoryContainer,
} from 'pages/Patient/PatientDetail/QualityTickets/QualityTickets.css';
import { theme, Tooltip } from '@candidco/enamel';

type QualitTicketSectionProps = {
  qualityTickets: QualityTicketsQuery['getQualityTicketsData'];
};

const QualityTicketSection = (props: QualitTicketSectionProps) => {
  const { qualityTickets } = props;
  const [sortedTickets, setSortedTickets] = useState<
    QualityTicketsQuery['getQualityTicketsData']
  >([]);
  const dateComparator = (a: QualityTickets, b: QualityTickets) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  };

  useEffect(() => {
    if (qualityTickets) {
      setSortedTickets(qualityTickets.sort(dateComparator));
    }
  }, [qualityTickets]);

  return (
    <TicketContainer>
      {sortedTickets.map((ticket, index) => {
        const escalationCategory = ticket.escalationCategory.split(';');
        let cumulativeLength = 0;
        let overflow = 0;
        const displayCategories = escalationCategory.reduce(
          (acc: string[], category: string, _index) => {
            if (cumulativeLength + category.length < 35) {
              acc.push(category);
              cumulativeLength += category.length;
            } else {
              overflow++;
            }
            return acc;
          },
          []
        );
        const overflowCategories = escalationCategory.slice(
          displayCategories.length
        );
        if (overflow) {
          displayCategories.push(`+${overflow}`);
        }
        const dateFormatter = Intl.DateTimeFormat('en', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        });
        const formattedDate = dateFormatter.format(new Date(ticket.createdAt));
        const simplifiedTicketStatus =
          ticket.ticketStatus === 'Closed' ? 'Closed' : 'Open';
        return (
          <RowContainer key={`ticket${index}`}>
            <RowItem>
              <StatusBadge
                variant={'default'}
                $status={simplifiedTicketStatus}
                label={simplifiedTicketStatus}
                size={'tiny'}
              />
            </RowItem>
            <h4>{formattedDate}</h4>
            <CategoryContainer>
              {displayCategories.map((category) =>
                category.includes('+') ? (
                  <Tooltip
                    title={overflowCategories.join(', ')}
                    key={category}
                    color={theme.colors.black90}
                    placement="top"
                  >
                    <OverflowCategoryBadge
                      label={category}
                      size={'tiny'}
                      variant={'default'}
                    />
                  </Tooltip>
                ) : (
                  <CategoryBadge
                    key={category}
                    label={category}
                    size={'tiny'}
                    variant={'outlined'}
                  />
                )
              )}
            </CategoryContainer>
          </RowContainer>
        );
      })}
    </TicketContainer>
  );
};

export default QualityTicketSection;
