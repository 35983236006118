import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Loading, NotificationContext } from '@candidco/enamel';
import { PageSection } from 'styles/layout.css';
import { Container } from 'components/FileUpload/FileUpload.css';
import FileUpload from 'components/FileUpload';
import { Overline } from 'components/PatientHeader/PatientHeader.css';
import {
  FileNameContainer,
  FileTypesText,
  FormContainerBorderless,
  InfoBlocks,
  SubHeading,
  UploadContainerSmall,
  ButtonContainer,
} from 'pages/TpCentralEnhanced/UploadForm/UploadForm.css';
import {
  AddPartDocument,
  AddPartMutation,
  AddPartMutationVariables,
  AddTreatmentPlanToolingDocument,
  AddTreatmentPlanToolingMutation,
  GetMaterialUploadDataDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  Manufacturers,
  MutationsAddTreatmentPlanToolingArgs,
  PartTypes,
  TreatmentPlanningSoftware,
  TreatmentPlanStaging,
  TreatmentPlanTooling,
  TreatmentPlanToolingTypes,
} from 'generated/core/graphql';
import { useGetConsolidatedOrderItemsByCaseRefLazyQuery } from 'generated/legacy/graphql';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import { StringMap } from 'utils/types';
import { formatFilesNames } from 'utils/materials';
import { CaseData, ContextProps } from 'pages/TpCentralEnhanced/types';
import readFile from 'utils/readFile';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CheckedCircle from 'assets/checked-circle.svg?react';
import RedX from 'assets/delete.svg?react';
import { CaseTypeNames } from 'constants/Case';
import { isNotNil } from 'utils/typeCheck';
import { REFACTOR_ANY } from '@Types/refactor';

// Hard coded list of manufacturable product types here to avoid grabbing the wrong order item.
const MANUFACTURABLE_PRODUCT_TYPES = ['ALIGNER_GOOD', 'RETAINER'];

type OrderItem = {
  orderItemRef: string;
  quantity: number;
  order: {
    orderDate: string;
  };
  product: {
    productType: string;
  };
};

const UploadForm = ({
  caseData,
  customerRef,
  latestTreatmentPlanStaging,
}: {
  caseData: CaseData;
  customerRef: string;
  latestTreatmentPlanStaging: TreatmentPlanStaging;
}) => {
  const { showNotification } = useContext(NotificationContext);
  const [orderItem, setOrderItem] = useState<OrderItem>();
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [zipFile, setZipFile] = useState<REFACTOR_ANY>(null);
  const { t } = useTranslation();
  const { caseRef, krakenCaseIdentifier, caseType } = caseData;

  // Reset state function
  const resetState = () => {
    setIsUploadingFile(false);
    setZipFile(null);
  };

  // GraphQL hooks
  const [getMaterialUploadData] = useGQLQuery<
    GetMaterialUploadDataQuery,
    GetMaterialUploadDataQueryVariables
  >(GetMaterialUploadDataDocument, true);
  const [getAddTreatmentPlanToolingMutation] = useGQLMutation<
    AddTreatmentPlanToolingMutation,
    MutationsAddTreatmentPlanToolingArgs
  >(AddTreatmentPlanToolingDocument, true);
  const [addPart] = useGQLMutation<AddPartMutation, AddPartMutationVariables>(
    AddPartDocument,
    true
  );
  const [getConsolidatedOrderItems] =
    useGetConsolidatedOrderItemsByCaseRefLazyQuery();

  // Fetch and set orderItemRef on component mount
  useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const consolidatedOrderItems = await getConsolidatedOrderItems({
          variables: { caseRef },
        });

        console.log('consolidatedOrderItems', consolidatedOrderItems);
        const orderItems = (
          consolidatedOrderItems.data?.consolidatedOrderItems ?? []
        )
          .filter(isNotNil)
          .map((orderItem) => orderItem as OrderItem)
          .filter((oi) =>
            MANUFACTURABLE_PRODUCT_TYPES.includes(
              oi?.product?.productType ?? ''
            )
          )
          .sort(
            (a, b) =>
              new Date(b.order.orderDate).getTime() -
              new Date(a.order.orderDate).getTime()
          );

        if (orderItems.length > 0) {
          setOrderItem(orderItems[0]);
        }
      } catch (error) {
        console.error('Error fetching order items:', error);
      }
    };

    fetchOrderItems();
  }, [caseRef]);

  // Log orderItem for debugging
  console.log('orderItem', orderItem);
  console.log('caseData', caseData);

  // Handle form submission
  const handleSubmit = async () => {
    setIsUploadingFile(true);

    if (zipFile && !isUploadingFile) {
      try {
        const filename = zipFile[0].file.name;

        // Get material upload data
        const getMaterialUploadDataResult = await getMaterialUploadData({
          patientId: parseInt(customerRef),
          fileName: filename,
        });

        const fields: StringMap =
          getMaterialUploadDataResult?.getMaterialUploadData?.fields;
        const url = getMaterialUploadDataResult?.getMaterialUploadData?.url!;
        const awsFileLocation =
          getMaterialUploadDataResult?.getMaterialUploadData?.awsLocation;

        // Prepare form data
        const data = new FormData();
        Object.entries(fields).forEach(([key, value]) =>
          data.append(key, value)
        );
        data.append('file', zipFile[0].file);

        // Upload file to the specified URL
        await axios({
          method: 'POST',
          url,
          data,
          headers: {
            'Content-Type': zipFile.type,
          },
        });

        // Check if orderItemRef is available
        if (!orderItem?.orderItemRef) {
          showNotification(
            'Could not find order item ref, so we cannot proceed. Please file a bug report.',
            'error'
          );
          setIsUploadingFile(false);
          return;
        }

        // Add treatment plan tooling
        const treatmentPlanStagingId = latestTreatmentPlanStaging.id;
        const addTreatmentPlanToolingResult =
          await getAddTreatmentPlanToolingMutation({
            treatmentPlanStagingId,
            treatmentPlanToolingType: TreatmentPlanToolingTypes.AlignerTooling,
            patientId: parseInt(customerRef),
            caseRef: caseRef,
            software: TreatmentPlanningSoftware.Vision,
            creationResources: {
              vision: {
                manufacturingZipFileAwsLocation: awsFileLocation!,
              },
            },
            orderItemRef: orderItem.orderItemRef,
          });

        const prerequisiteMaterialIds = [
          addTreatmentPlanToolingResult?.addTreatmentPlanTooling
            ?.treatmentPlanTooling?.id || '',
        ];

        // Add part
        await addPart({
          caseRef,
          patientId: parseInt(customerRef),
          partType:
            caseType.name === CaseTypeNames.RETAINER
              ? PartTypes.Retainer
              : PartTypes.Aligner,
          manufacturer: Manufacturers.Kraken,
          prerequisiteMaterialIds,
          producePart: true,
          manufacturerData: { kraken: { krakenCaseIdentifier } },
          orderItemRef: orderItem.orderItemRef,
          quantity: orderItem.quantity,
        });

        showNotification(
          'Successfully uploaded manufacturing files.',
          'success'
        );
        setIsUploadingFile(false);
      } catch (e) {
        showNotification('Error uploading manufacturing files.', 'error');
        resetState();
      }
    }
  };

  // Handle file selection
  const handleSelectedFile = async (files: any) => {
    if (!files) {
      return;
    }

    const fileArray = formatFilesNames(Array.from(files));
    const selected = await Promise.all(
      fileArray.map(async (file) => {
        const fileSrc = await new Promise<string>((resolve) =>
          readFile(file, resolve)
        );

        return {
          file,
          fileSrc,
        };
      })
    );

    setZipFile(selected);
  };

  // Render main content
  const renderMainContent = () => (
    <>
      <UploadContainerSmall>
        <FileUpload
          isHorizontal
          fileType="zip"
          allowMultipleFiles={false}
          onSelectFile={handleSelectedFile}
          limitFileSize={false}
        />
      </UploadContainerSmall>
      <FileTypesText>
        <b>{t('tp_central_enhanced.accepted_file_types')}:</b> .zip
      </FileTypesText>
      <FileNameContainer>
        {zipFile?.name ? `${t('common.selected')}: ${zipFile.name}` : ''}
      </FileNameContainer>
      <ButtonContainer>
        {zipFile && (
          <Button
            buttonType="secondary"
            isLoading={isUploadingFile}
            isShort
            onClick={handleSubmit}
          >
            {t('tp_central_enhanced.upload_and_push_to_production')}
          </Button>
        )}
      </ButtonContainer>
    </>
  );

  // Render component
  return isUploadingFile ? (
    <Container alignContent="center">
      <Loading />
      <SubHeading>{t('common.this_could_take_a_few_minutes')}</SubHeading>
    </Container>
  ) : (
    <PageSection isNarrow>{renderMainContent()}</PageSection>
  );
};

export default UploadForm;

export const ManufacturingFileVersions = ({
  selectedTreatmentPlanStaging,
  parts,
}: {
  selectedTreatmentPlanStaging: TreatmentPlanStaging;
  parts: ContextProps['parts'];
}) => {
  const { 'tp-central-ui-enhancements': tpCentralEnhancedFlag } = useFlags();
  const { t } = useTranslation();

  const partProducibleMap: { [key: string]: boolean } = {};
  parts?.forEach((part) => {
    if (part?.treatmentPlanTooling?.id) {
      partProducibleMap[part.treatmentPlanTooling.id] = part?.producible;
    }
  });

  const toolings = selectedTreatmentPlanStaging?.treatmentPlanToolings;
  let sortedToolings: TreatmentPlanTooling[] = [];
  if (toolings?.length) {
    sortedToolings = [...toolings].sort((a, b) => {
      return new Date(a?.createdAt) > new Date(b?.createdAt) ? -1 : 1;
    });
  }

  return (
    <>
      <FormContainerBorderless>
        {sortedToolings?.map((tooling, idx) => (
          <InfoBlocks>
            <li>
              <Overline data-testid="overline-heading">
                {t('common.version')}
              </Overline>
              <h3>{idx + 1}</h3>
            </li>
            <li>
              <Overline data-testid="overline-heading">
                {t('common.last_updated')}
              </Overline>
              <h3>
                {tooling?.createdAt
                  ? new Date(tooling.createdAt).toLocaleDateString('en-CA') // This locale is yyyy-mm-dd
                  : 'N/A'}
              </h3>
            </li>
            {tpCentralEnhancedFlag && (
              <li>
                <Overline data-testid="overline-heading">
                  {t('tp_central_enhanced.gcode_status')}
                </Overline>
                <h3>
                  {partProducibleMap[tooling.id] ? (
                    <>
                      Producible <CheckedCircle />
                    </>
                  ) : (
                    <>
                      Not producible <RedX />
                    </>
                  )}
                </h3>
              </li>
            )}
          </InfoBlocks>
        ))}
      </FormContainerBorderless>
    </>
  );
};
