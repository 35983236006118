import { createGlobalStyle } from 'styled-components';
import { Theme } from '@candidco/enamel';

export const GlobalStyles = createGlobalStyle<Theme>`
  html,
  body {
    height: 100%;
  }

  body {
    background: ${({ theme }) => theme.colors.white};
  }

  .app-root {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100%;
  }

  // Utility helper to hide page's scrollbar anytime an element with the class HideRootScrollbars is present on the page
  :root:has(.HideRootScrollbars) {
    overflow: hidden;
  }
`;
