import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  AddTreatmentPlanStagingDocument,
  AddTreatmentPlanStagingMutation,
  AddTreatmentPlanStagingMutationVariables,
  CreateOrderDocument,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  GetOrdersDocument,
  GetOrdersQuery,
  GetOrdersQueryVariables,
  GetPracticePaymentStatusDocument,
  GetPracticePaymentStatusQuery,
  GetPracticePaymentStatusQueryVariables,
  GetProductVariantsDocument,
  GetProductVariantsQuery,
  GetProductVariantsQueryVariables,
} from 'generated/core/graphql';

import {
  GetAllPracticesDocument,
  GetAllPracticesQuery,
  GetAllPracticesQueryVariables,
  GetPracticesDocument,
  GetPracticesQuery,
  GetPracticesQueryVariables,
} from 'generated/legacy/graphql';

import { coreClient, client as legacyClient } from 'gql/GraphQLProvider';
import {
  syntheticMutateDecorator,
  mutateDecorator,
  queryDecorator,
} from 'state/api/utils';
import { addTemplatedTreatmentPlanStaging } from 'state/api/synthetics';

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'invalid://unknown/use_queryFn_instead',
  }),
  tagTypes: [
    'Order',
    'Practice',
    'PracticePaymentStatus',
    'ProductVariant',
    'TreatmentPlanStaging',
  ],
  keepUnusedDataFor: 60 /* Default is 60; in seconds */,
  endpoints: (builder) => ({
    /* order */
    createOrder: builder.mutation({
      invalidatesTags: ['Order'],
      queryFn: mutateDecorator(
        coreClient.mutate<CreateOrderMutation, CreateOrderMutationVariables>,
        'createOrder',
        CreateOrderDocument
      ),
    }),
    getOrders: builder.query({
      providesTags: ['Order'],
      queryFn: queryDecorator(
        coreClient.query<GetOrdersQuery, GetOrdersQueryVariables>,
        'orders',
        GetOrdersDocument
      ),
    }),

    /* practice */
    getPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetAllPracticesQuery, GetAllPracticesQueryVariables>,
        'practices',
        GetAllPracticesDocument
      ),
    }),
    getFilteredPractices: builder.query({
      providesTags: ['Practice'],
      queryFn: queryDecorator(
        legacyClient.query<GetPracticesQuery, GetPracticesQueryVariables>,
        'practices',
        GetPracticesDocument
      ),
    }),

    /* practice payment status */
    getPracticePaymentStatus: builder.query({
      providesTags: ['PracticePaymentStatus'],
      queryFn: queryDecorator(
        coreClient.query<
          GetPracticePaymentStatusQuery,
          GetPracticePaymentStatusQueryVariables
        >,
        'practicePaymentStatus',
        GetPracticePaymentStatusDocument
      ),
    }),

    /* product variants */
    getProductVariants: builder.query({
      providesTags: ['ProductVariant'],
      queryFn: queryDecorator(
        coreClient.query<
          GetProductVariantsQuery,
          GetProductVariantsQueryVariables
        >,
        'productVariants',
        GetProductVariantsDocument
      ),
    }),

    /* treatment plan staging */
    addTemplatedTreatmentPlanStaging: builder.mutation({
      invalidatesTags: ['TreatmentPlanStaging'],
      queryFn: syntheticMutateDecorator(addTemplatedTreatmentPlanStaging),
    }),
    addTreatmentPlanStaging: builder.mutation({
      invalidatesTags: ['TreatmentPlanStaging'],
      queryFn: mutateDecorator(
        coreClient.mutate<
          AddTreatmentPlanStagingMutation,
          AddTreatmentPlanStagingMutationVariables
        >,
        'addTreatmentPlanStaging',
        AddTreatmentPlanStagingDocument
      ),
    }),
  }),
});

export default api;
